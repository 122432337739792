:root {
    /* Fonts */
    --font-h1: normal 600 1.25rem/1.625rem SR Lab;
    --font-h2: normal 400 1.25rem/1.625rem SR Lab;
    --font-h3: normal 600 1rem/1.4rem SR Lab;
    --font-h4: normal 400 1rem/1.4rem SR Lab;
    --font-h5: normal 600 1rem/1.4rem SR Lab;
    --font-body-large: normal 400 1rem/1.4rem SR Lab;
    --font-body: normal 400 1.125/1.5rem SR Lab;
    --font-caption: normal 400 0.875rem/1.225rem SR Lab;
    --font-overline1: normal 500 0.875rem/1.225rem SR Lab;
    --font-overline2: normal 500 0.75rem/0.975rem SR Lab;
}
