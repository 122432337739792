﻿@mixin box-shadow {
  box-shadow: 0 0 40px 0 var(--color-box-shadow);
}

@mixin box-shadow-small($color: var(--color-box-shadow)) {
    box-shadow: 0 0 $space-medium 0 $color;
}

@mixin box-shadow-contrast($color: $color-box-shadow-contrast) {
    box-shadow: 0 0 $space-medium 0 $color;
}

@mixin card-box-shadow {
    box-shadow: 0px $space-xs $space-small $space-xs var(--color-box-shadow);
}
