.info-teasers {
    @include caption-style;
    @include caption-style-strong;
    flex: 1;

    @include mq-desktop-large {
        font-size: inherit;
        line-height: inherit;
    }

    h2 {
        @include caption-style;
        @include caption-style-strong;

        @include mq-desktop-large {
            font-size: inherit;
            line-height: inherit;
        }
    }
}

.info-teasers__list {
    @include mq-tablet-portrait {
        display: flex;
        flex-wrap: wrap;
    }
}

.info-teasers__list-item + .info-teasers__list-item {
    @include border-top;

    @include mq-desktop-large {
        @include border-left;
        border-top: 0;
    }
}

@include mq-tablet-portrait {
    .info-teasers__list-item {
        @include flex-center;
    }
}
