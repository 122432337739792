﻿.track + .track {
    @include border-top;
}

.track__content {
    @include flex(initial);
}

.track__ranking {
    @include flex;
    @include border-right;
    @include cell-padding-vertical;
    width: $space-small * 7;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;

    @include mq-mobile-large {
        width: $space-small * 10;
    }
}

.track__ranking-current {
    @include body-font-bold;
}

.track__ranking-previous {
    @include caption-style($color-gray);
    @include divider-left-xs;
}

.track__play {
    @include divider-right-base;

    @include mq-mobile-large {
        @include divider-right-medium;
    }
}

.track__details-points {
    @include divider-bottom-base;

    @include mq-mobile-large {
        margin: 0;
    }
}

.track__details-search {
    @include flex-center;

    > * + * {
        @include divider-left-base;
        width: auto;
    }
}

.track__info {
    @include flex;
    @include cell-padding;
    flex: 1;
    position: relative;
}

.track__time-on-list {
    color: $color-gray;
    flex-shrink: 0;
    margin-left: auto;
}

.track__link,
.track__info > .track__title {
    @include body-font-bold;
    @include divider-right-small;
}
