﻿$traffic-green: #43ae28;
$traffic-yellow: #ffd100;
$traffic-blue: #3678c8;
$traffic-orange: #ff6f00;
$traffic-red: #9d0000;

$traffic-priority-text-color: #000;
$traffic-priority-contrast-text-color: #fff;

$traffic-priority-none-color: $traffic-green;
$traffic-priority-none-bg: mix(#fff, $traffic-priority-none-color, 92%);

$traffic-priority-info-color: $traffic-blue;
$traffic-priority-info-bg: mix(#fff, $traffic-priority-info-color, 92%);
$traffic-priority-info-bg-hover: mix(#fff, $traffic-priority-info-color, 86%);

$traffic-priority-small-color: $traffic-yellow;
$traffic-priority-small-bg: mix(#fff, $traffic-priority-small-color, 92%);
$traffic-priority-small-bg-hover: mix(#fff, $traffic-priority-small-color, 86%);

$traffic-priority-normal-color: $traffic-orange;
$traffic-priority-normal-bg: mix(#fff, $traffic-priority-normal-color, 92%);
$traffic-priority-normal-bg-hover: mix(#fff, $traffic-priority-normal-color, 86%);

$traffic-priority-major-color: $traffic-red;
$traffic-priority-major-bg: mix(#fff, $traffic-priority-major-color, 92%);
$traffic-priority-major-bg-hover: mix(#fff, $traffic-priority-major-color, 86%);

.js-on .hide-while-loading div {
    visibility: hidden;
}

.ajax-loading-indicator {
    background: url('../../content/img/frontend/ajax-loader.gif') 50% 50% no-repeat;
    padding-bottom: 100px;
}

.traffic-contact__header {
    @include ui-section-header;
}

.traffic-contact__title {
    @include h3-style;
}

.traffic-contact__footer {
    @include grid-gutter;
    @include flex-center;
    @include divider-bottom-medium;
}

.traffic-page__phone-icon {
    @include h-item;
    @include divider-right-base;
    width: $space-large;
    height: $space-large;
}

.category-list {
    @extend %component-design;
    list-style-type: none;
    overflow: hidden;

    .category {
        @include border-bottom;
    }
}

.traffic-message-list__category-icon {
    @include h-item;
    @include divider-right-base;
    width: $space-large;
    height: $space-large;
}

.traffic-header {
    @include flex-center;
    @include cell-padding;
    @include reset-input-styles;
    width: 100%;
    color: var(--color-foreground-primary);
}

.traffic-header__counter {
    @include border-radius;
    padding: $space-xs;
    color: $color-white;
    font-weight: bold;
    margin-left: auto;
}

.traffic-category > .message-list {
    display: none;
    cursor: pointer;
}

.traffic-category.expanded > .message-list {
    display: block;
}

.traffic-category--none .traffic-header__counter {
    background-color: $traffic-priority-none-color;
}

.traffic-category--small .traffic-header__counter {
    background-color: $traffic-priority-small-color;
}

.traffic-category--info .traffic-header__counter {
    background-color: $traffic-priority-info-color;
}

.traffic-category--normal .traffic-header__counter {
    background-color: $traffic-priority-normal-color;
}

.traffic-category--large .traffic-header__counter,
.traffic-category--major .traffic-header__counter {
    background-color: $traffic-priority-major-color;
}

.traffic-header__title > * {
    @include h-item;
}

.traffic-message {
    @include cell-padding;
    border-left: solid 2px transparent;
    overflow: hidden;

    @include dark-mode {
        background: none !important;
    }

    .exact-location {
        font-weight: bold;
        margin-top: 5px;
    }

    .small {
        margin-right: 24px;
    }

    p {
        @include divider-top-half;
    }

    h3.small span {
        @include divider-top-half;
        float: left;
        vertical-align: text-bottom;
    }
}

.traffic-message__list-item + .traffic-message__list-item {
    @include border-top;
}

.traffic-message__body > * {
    @include divider-bottom-base;
}

.traffic-message__header {
    @include divider-bottom-base;
}

.traffic-message--none {
    background-color: $traffic-priority-none-bg;
    border-color: $traffic-priority-none-color;
}

.traffic-message__label {
    @include border-radius;
    @include overline-style-small;
    color: $color-white;
    padding: $space-xs;
    display: inline-block;
}

.traffic-message__label,
.metadata {
    @include h-item;
    @include divider-right-base;
}

.toggler-content {
    display: none;
}

.traffic-message.traffic-message--active {
    background-color: $color-light-yellow;
    border-left-width: 6px;
}

.traffic-message--none {
    border-color: $traffic-priority-none-color;
    background-color: $traffic-priority-none-bg;
}

.traffic-message--none .traffic-message__label {
    background-color: $traffic-priority-none-color;
}

.traffic-message--small {
    border-color: $traffic-priority-small-color;
    background-color: $traffic-priority-small-bg;
}

.traffic-message--small .traffic-message__label {
    background-color: $traffic-priority-small-color;
}

.traffic-message--info {
    border-color: $traffic-priority-info-color;
    background-color: $traffic-priority-info-bg;
}

.traffic-message--info .traffic-message__label {
    background-color: $traffic-priority-info-color;
    color: $traffic-priority-contrast-text-color;
}

.traffic-message--normal {
    border-color: $traffic-priority-normal-color;
    background-color: $traffic-priority-normal-bg;
}

.traffic-message--normal .traffic-message__label {
    background-color: $traffic-priority-normal-color;
}

.traffic-message--large,
.traffic-message--major {
    border-color: $traffic-priority-major-color;
    background-color: $traffic-priority-major-bg;
}

.traffic-message--large .traffic-message__label,
.traffic-message--major .traffic-message__label {
    background-color: $traffic-priority-normal-color;
    color: $traffic-priority-contrast-text-color;
}

.traffic-heading-container {
    float: left;

    > h2 {
        > a {
            font-weight: normal;
            color: $color-white;
            text-transform: uppercase;
            margin: 4px 10px;
            display: block;
        }
    }
}

.choose-traffic-area {
    float: left;
    margin: 8px 0;

    > .dropdown-wrapper {
        background-color: #6b005b;
        border-radius: 4px;
        padding: 6px 10px;

        > .dropdown:hover {
            background-color: transparent;
        }
    }

    > .dropdown {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
    }
}

.column ul {
    border-right: 1px solid #e5e5e5;
}

.column.last ul {
    border: 0;
}

#traffic-areas {
    background: $color-white;
}

.traffic-area-name {
    width: 85%;
    color: $color-white;
    margin: 0 10px;
}

.pac-container {
    > .pac-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.gmnoprint a,
.gmnoprint span {
    display: none;
}

.traffic__map-info {
    @include card-design;
    @include divider-bottom-medium;
    @include cell-padding;
}

.traffic-container {
    margin-bottom: $space-small;
}

@include mq-tablet-portrait {
    .toggler-content {
        display: block;
    }

    .traffic-small.hover:hover {
        background-color: $traffic-priority-small-bg-hover;
    }

    .traffic-normal.hover:hover {
        background-color: $traffic-priority-normal-bg-hover;
    }

    .traffic-large.hover:hover,
    .traffic-major.hover:hover {
        background-color: $traffic-priority-major-bg-hover;
    }

    .traffic-info.hover:hover {
        background-color: $traffic-priority-info-bg-hover;
    }
}
