﻿$group-description-negative-margin-desktop: 18px;

.group-description {
    @include divider-bottom-medium;
    display: table;
}

.group-description-timeline {
    @extend %component-design;
    @include border-bottom;
    margin: 0;
    padding: 0;
}

.group-description-timeline__header {
    @extend %puff-inner-element;
    @extend %puff-padding-top;
}

.group-description-left,
.group-description-right {
    vertical-align: top;
    display: block;
}

.group-description {
    padding-top: $space-xl;
}

.group-description-left {
    width: 100%;
}

.group-description-right {
    width: 100%;
}

.group-description__controls {
    @include grid-gutter;
    @include flex-center;
    @include flex-wrap;
    @include divider-bottom-medium;
}

.group-description__controls > * {
    @include divider-right-medium;
}

.group-description__header,
.group-description__preamble {
    @include divider-bottom-base;
}

.group-description__heading-wrapper {
    @include mq-tablet-portrait {
        padding-right: $space-medium;
    }
}

.group-description__header {
    @include flex;
}

.group-description__header,
.group-description__image,
.group-description__preamble {
    @include grid-gutter;
}

.group-description__image > img {
    min-width: 220px;
}

@include mq-mobile-large {
    .group-description-right {
        display: table-cell;
    }

    .group-description-left {
        display: table-cell;
    }

    .group-description__share {
        margin-bottom: 0;
    }
}

@include mq-tablet-portrait {
    
    .group-description__image > img {
        min-width: 260px;
    }
}

@include mq-desktop-large {
    .group-description__image > img {
        min-width: 260px;
    }
}
