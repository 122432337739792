@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';










































































































































$search-icon-size: $space-small * 6;
$search-input-offset-padding: $space-small * 5;

.searchField {
    @include reset-input-styles;
    @include cell-padding-base;
    @include border-radius;
    width: 100%;
    padding-left: $search-input-offset-padding;
    background-color: var(--color-background-light);
    color: var(--color-foreground-primary);
    transition: width 250ms $ease-out-circ;

    &.active {
        @include mq-tablet-portrait {
            width: 75%;
        }
    }
}

.reset {
    @include reset-input-styles;
    @include cell-padding-vertical-base;
    @include cell-padding-horizontal;
    @include flex-center;
    margin-left: -$search-icon-size;
    color: var(--color-foreground-primary);
}

form {
    @include flex-center;
    margin: $space-medium;
    position: relative;
}

.cancel {
    width: 0;
    padding: 0;
    text-align: right;
    flex-shrink: 0;
    max-width: $space-small * 9;
}

.cancel-wrapper {
    display: none;
    @include mq-tablet-portrait {
        display: block;
    }
}

button {
    @include reset-input-styles;
    @include cell-padding-horizontal;
    padding-right: 0;
    overflow: hidden;
    color: var(--color-foreground-primary);
}

.search-icon {
    position: absolute;
    top: 0;
    height: 100%;
    left: $space-medium;
    pointer-events: none;
}
