$sr-btn-default-height: 28px;
$sr-btn-tag-height: 30px;
$sr-btn-solid-height: $space-small * 6;

$sr-btn-icon-container--width: 34px;
$sr-btn-padding: 1px;
$sr-btn-padding--large: 12px;
$sr-btn-padding--tight: 10px;
$sr-btn-with-icon--padding-left: 34px;
$sr-btn-flyout--padding-right: 26px;
$sr-btn-color: var(--color-background-light);
$sr-btn-border-width: 2px;
$sr-btn-border-bottom-color-1: rgba(0,0,0,0.2);
$sr-btn-border-bottom-color-2: rgba(0,0,0,0.7);

@mixin sr-btn-base {
    @include card-hover;
    @include caption-style;
    @include body-font-bold;
    @include border-radius;
    display: inline-block;
    vertical-align: middle;
    padding: $sr-btn-padding 0;
    min-height: $sr-btn-default-height;
    text-decoration: none;
    text-align: left;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
}

@mixin sr-btn-with-icon {
    position: relative;
}

@mixin sr-btn-with-icon--on-left {
    padding-left: $sr-btn-with-icon--padding-left;

    .sr-btn__icon-container {
        left: 0;
    }
}

.sr-btn {
    @include sr-btn-base();

    &:hover {
        .sr-btn__label {
            text-decoration: underline;
        }
    }
}

.sr-btn__icon-container {
    display: none;
    height: $sr-btn-default-height;
}

.sr-btn__label {
    display: inline-block;
    vertical-align: middle;
}

.sr-btn--icon {
    @include sr-btn-with-icon--on-left();
    position: relative;
    padding-left: 24px;

    .sr-btn__icon-container {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0;
        width: 24px;

        &:before {
            margin: 0;
        }
    }
}

.sr-btn--solid {
    padding: $space-medium;
    min-height: $sr-btn-solid-height;
    background-color: $sr-btn-color;
    display: block;
    width: 100%;
}

.sr-btn--solid.sr-btn--icon {
    padding-left: $sr-btn-with-icon--padding-left;

    .sr-btn__icon-container {
        width: 38px;
        text-align: center;
        height: 100%;
    }
}

.sr-btn--inactive {
    display: none;
}

.sr-btn--centered {
    text-align: center;
}

.sr-btn--solid.sr-btn--centered {
    vertical-align: middle;

    .sr-btn__icon-container {
        position: relative;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
    }
}

button.sr-btn--solid.sr-btn--centered {
    padding: 0 13px;
}

.sr-btn--white {
    background-color: $color-white;
}

.sr-btn--text-ellipsis {
    @include flex;

    .sr-btn__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.sr-btn .sr-btn__icon-container--svg {
    @include divider-right-base();
    position: relative;
}

.sr-btn--svg {
    @include flex-center();
    padding: $space-small 0;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
        box-shadow: none;
        color: $color-gray;
    }
}
