﻿@mixin component-divider {
    @include divider-bottom-medium;
}

%component-design {
    background-color: var(--color-background-primary);
    padding: 0;
    overflow: hidden;
    margin-bottom: $space-medium;

    @include mq-tablet-portrait {
        @include box-shadow-small;
        @include border;
        border-radius: $border-radius;
    }
}

//Base-styles för block / komponenter som ska sorteras vertikalt och ha enskild bakgrundsfärg osv
@mixin component-design($background: var(--color-background-primary), $padding: 0) {
    background-color: $background;
    padding: 0;
    overflow: hidden;
    margin-bottom: $space-medium;

    @include mq-tablet-portrait {
        border-radius: $border-radius;
    }
}

//Används för att öka marginal vertikalt mellan komponenter från $tablet-portrait och uppåt
@mixin component-design-large {
    border-radius: $border-radius;
}

@mixin component-design-no-margin($background: $color-white, $padding: 0) {
    background-color: $background;
    padding: $padding;
    overflow: hidden;
}
