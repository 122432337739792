.live-module {
    @include card-design;
    overflow: hidden;

    @include mq-desktop {
        display: flex;

        > * {
            flex: 1;
        }

        @supports (display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        margin: 0;
    }

    .publication-theme {
        padding-left: 0;
        padding-top: 0;
    }

    header {
        @include grid-gutter;
        @include cell-padding;
        position: relative;

        @include mq-tablet-portrait {
            @include flex-center;
        }
    }
}

.live-module__header-inner {
    width: 100%;
}
