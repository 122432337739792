﻿//Widths
$page-max-width: 77rem;
$page-min-width: 17.5rem;
$container-narrow-width: 50rem;

// Image widhts
$thumbnail-width: 3.5rem;

//Heights
$menu-height: 3rem;
$menu-height-large: 4rem;
$form-input-height: 3rem;
$compact-menu-link-height: 3.5rem;
