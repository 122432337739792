.tab-navigation {
    a,
    button,
    input,
    textarea {
        &:focus {
            @include focus-style;
        }
    }

    input:focus + label {
        @include focus-style;
    }
}

.navigate-to {
    width: 100%;
    text-align: center;
    background-color: $color-light-yellow;
    overflow: hidden;
    @include dark-mode {
        background-color: var(--color-background-primary-inverted); 
    }
}

.navigate-to a:focus {
    @include cell-padding;
    display: block;
    height: auto;
}

.navigate-to a {
    display: block;
    height: 0;
    @include dark-mode {
        color: var(--color-foreground-primary-inverted); 
    }
}

