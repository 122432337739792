﻿.sr-page__direct-module {
    width: 100%;
    background: $color-white;
}

.upcoming-module {
    @include card-design;
}

.upcoming-module__title {
    @include divider-bottom-base;
}

.upcoming-module__episode-list {
    @include grid-gutter;
    @include divider-bottom-medium;

    @include mq-tablet-portrait {
        display: flex;
        flex-wrap: wrap;
    }
}

.upcoming-module header {
    @include ui-section-header;
}
