.section-item {
    @include card-design;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.section-item__heading {
    @include card-heading;
}

.section-item__body {
    @include card-body;
    @include inner-divider-half;
}

.section-item__body a {
    display: block;
}

.section-item__description {
    word-break: break-word;
}
