﻿.unit-footer {
    background: $color-white;
    overflow: hidden;

    .unit-info,
    .contact-info {
        padding-bottom: 12px;
    }
    
    .unit-footer-border {
        border-top-width:3px;
        border-top-style:solid;
    }    

    .unit-info {
        .section-heading {
            border-bottom: 0;
        }
    }

    .contact-info {
        border-top-width: 3px;
        border-top-style: solid;

        strong {
            display: block;
        }

        .contact-link {
            display: inline-block;
            padding-bottom: 4px;
        }      
    }

    .follow {
        
        > ul {
            margin: 2px 0 15px -3px;
            overflow: hidden;

            > li {
                margin-bottom: 4px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: top;

                &.last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
