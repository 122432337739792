﻿.publication-text {
    width: 100%;
}

.publication-text p {
    @include divider-bottom-medium;

    &:last-child {
        margin: 0;
    }
}

.publication-text a {
    @include link-style;
}
