﻿$episode-list-item__thumbnail--width--medium: 120px;
$episode-list-item__thumbnail--width--large: 160px;
$episode-list-item__description--max-width: 640px;
$episode-list-item__thumbnail-width: $space-small * 7;
$episode-list-item__thumbnail-height: $episode-list-item__thumbnail-width;

.episode-list-item + .episode-list-item {
  @include border-top;
}

.episode-list-item__content + .episode-list-item__description {
  @include divider-top;
}

.episode-list-item__thumbnail {
  width: $episode-list-item__thumbnail-width;
  height: $episode-list-item__thumbnail-height;
}

.episode-list-item__description {
  max-width: $episode-list-item__description--max-width;
}

.episode-list-item__controls {
  margin-bottom: -$space-small;

  @include mq-tablet-portrait {
    margin-bottom: -$space-medium;
  }
}

.episode-explorer__tab[aria-labelledby="kommande"] {
  .episode-list-item__controls {
    display: none;
  }
}

.episode-list--large-thumbnails {

  @include mq-tablet-portrait {
    .episode-list-item__thumbnail {
      width: $episode-list-item__thumbnail--width--medium;
      height: auto;
    }
  }
  @include mq-desktop-large {
    .episode-list-item__thumbnail {
      width: $episode-list-item__thumbnail--width--large;
    }
  }
}

.episode-list--large-thumbnails + .episode-list--large-thumbnails {
  @include border-top;
}
