$unit-header-padding-top: 11px;
$unit-header-padding-left: 14px;
$unit-header-ribbon-height: 35px;
$unit-header-padding-bottom: 8px;
$unit-header-margin-bottom: $space-medium;

.unit-header {
    @extend %component-design;
}

.unit-header__block--vinjett {
    display: block;
}

.unit-header__vinjett {
    display: none;
}

.unit-header__block--small {
    width: auto;
    font-weight: normal;

    @include mq-tablet-portrait {
        @include divider-left-medium;
        @include divider-bottom-medium;
        float: right;
    }
}

.unit-header__block--channelrtl {
    float: left;
    margin-left: 0;
    margin-right: $space-medium;
}

.unit-header__block--program {
    @include h1-style;
    padding: $space-medium $space-medium $space-small;
}

.unit-header__link {
    display: block;
    text-decoration: none;
}

.unit-header__description {
    padding: 0 $space-medium $space-medium;
    border-bottom: 1px solid $color-gray-lightest;
}

.unit-header__description--vinjett {
    display: block;
}

@include mq-tablet-portrait {
    $unit-header-padding-top: 24px;
    $unit-header-padding-left: 25px;
    $unit-header-ribbon-height: 49px;

    .unit-header__vinjett {
        display: block;
        position: relative;
    }

    .unit-header__block--vinjett {
        display: none;
    }

    .unit-header__vinjett-image {
        display: block;
        width: 100%;
    }

    .unit-header__description--vinjett {
        display: none;
    }

    .unit-header__vinjett--channel {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        padding: 0;
        margin: 0;

        .ribbon {
            padding: $space-medium;
        }
    }
}
