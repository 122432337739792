﻿$root-element-size: 16px;

//Gutters
$space-xs: .25rem;
$space-small: .5rem;
$space-smallmedium: .75rem;
$space-medium: 1rem;
$space-large: 1.5rem;
$space-xl: 2rem;
$space-xxl: 3rem;
$space-xxxl: 4.5rem;
