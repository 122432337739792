﻿.upcoming-episode {
    @include flex;
    align-items: baseline;

    a {
        display: block;
        padding: $space-medium $space-small;
    }

    &:nth-child(n+6) {
        display: none;
    }
}

.upcoming-episode__link {
    display: block;
}

@include mq-mobile-large {
    .upcoming-episode {
        padding-right: $space-medium;
    }

    .upcoming-episode:first-child {
        padding-left: 0;
    }
}
