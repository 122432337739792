@use "sass:math";

// Variables
@import 'variables/spacing';
@import 'variables/dimensions';
@import 'variables/border-variables';
@import 'variables/colors';
@import 'variables/z-indexes';
@import 'variables/easings';

// Typography
@import 'typography/fonts';

// Mixins
@import 'mixins/display';
@import 'mixins/background';
@import 'mixins/media-queries';
@import 'mixins/grid-gutters';
@import 'mixins/padding';
@import 'mixins/dividers';
@import 'mixins/borders';
@import 'mixins/border-header';
@import 'mixins/flex';
@import 'mixins/forms';
@import 'mixins/icon';
@import 'mixins/puff-common';
@import 'mixins/image';
@import 'mixins/box-shadow';
@import 'mixins/card';
@import 'mixins/card-list';
@import 'mixins/component-design';
@import 'mixins/container';
@import 'mixins/clickable-design';
@import 'mixins/button';
@import 'mixins/link';
@import 'mixins/thumbnail';
@import 'mixins/audio-heading';
@import 'mixins/full-page-style';
@import 'mixins/iframe';
@import 'mixins/forms';
@import 'mixins/ellipsis';
@import 'mixins/focus';
@import 'mixins/gradient';

// Ui
@import 'ui/ui-section-header';
@import 'ui/ui-show-more-button';

//Todo flytta in i egen fil
$column-gap: .5rem;
$section-padding: 1.25rem 1rem;
$section-padding-medium: 2rem 1rem;

@import '~bulma/sass/utilities/all';
@import '~bulma/sass/helpers/all';
@import '~bulma/sass/grid/columns';
@import '~bulma/sass/layout/section';
@import '~bulma/sass/elements/image';

//Globals
@import 'globals';

// Resets
@import 'resets/normalize';
@import 'resets/normalize-overrides';
@import 'resets/helpers';

@import 'color-scheme/color-schemes';

// Utils
@import 'utils/container';
@import 'utils/grid';
@import 'utils/global-styles';
@import 'utils/fixed-height';
@import 'utils/live-label';
@import 'utils/menu-scroll';
@import 'utils/section';
@import 'utils/card';

// Legacy web helpers based on Stil variables
@import 'utils/stil-legacy-web-helpers';

@import '../../../Components/site-components';

// Base
@import 'base/base';
@import 'base/typography';
@import 'base/sr-page';
@import 'base/layout';
@import 'base/modules';
@import 'base/theme';
@import 'base/unit-header';
@import 'base/keyword-list';
@import 'base/main-flow';
@import 'base/date-picker';
@import 'base/print';
@import 'base/tab-navigation-highlight';
@import 'base/all-programs';
@import 'base/music-toplist';
@import 'base/sitemap';
@import 'base/traffic';
@import 'base/start-page';
@import 'base/ettan';
@import 'base/collapsible-menu';
@import 'base/sr-btn';
@import 'base/sr-lnk';
@import 'base/sr-icon';
@import 'base/img';
@import 'base/share-button-list';
@import 'base/unit-footer';
@import 'base/program-footer';
@import 'base/aside-module';
@import 'base/clickable-content';
@import 'base/category-module';
@import 'base/factbox';
@import 'base/global-footer';
@import 'base/toggler';
@import 'base/rss-module';
@import 'base/text-archive-header';
@import 'base/no-javascript';
@import 'base/channel-navigation';
@import 'base/tabla-utskrift';
@import 'base/text-editor-content';
@import 'base/track-list';
@import 'base/track-list-item';
@import 'base/toplist-audio-box';
@import 'base/error-module';
@import 'base/toggle-arrow';
@import 'base/puff-flow';
@import 'base/puff-small';
@import 'base/puff-audio-large';
@import 'base/live-module';
@import 'base/preamble-text';
@import 'base/chronologic-flow';
@import 'base/puff-nedryckare';
@import 'base/puff-preamble';
@import 'base/program-listing';
@import 'base/rss-list';
@import 'base/header-section';
@import 'base/episode-details';
@import 'base/publication-preamble';
@import 'base/publication-text';
@import 'base/paragraph';
@import 'base/article-details';
@import 'base/group-description';
@import 'base/episode-group';
@import 'base/upcoming-episode-list';
@import 'base/upcoming-episode';
@import 'base/duration-item';
@import 'base/modal';
@import 'base/support-info';
@import 'base/header-label';
@import 'base/channel-direct';
@import 'base/episode-explorer';
@import 'base/episode-explorer-nav';
@import 'base/episode-group-list';
@import 'base/unit-episode-group';
@import 'base/unit-episode-group-list';
@import 'base/tag';
@import 'base/logo-header';
@import 'base/button-list';
@import 'base/back-to-top';
@import 'base/newsday';
@import 'base/information-page';
@import 'base/internal-link';
