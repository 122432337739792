﻿.modal--open{
    display: none;
}

.body-player--expanded {
    @include mq-tablet-portrait {
        overflow: hidden;
    }
}

.player--expanded {
    display: none;
    @include mq-tablet-portrait {
        display: block;
    }
}