$gradient-start: 35%;
$gradient-start--p4: 65%;

@mixin gradient($start, $stop) {
    background: linear-gradient(to right, $start 0%, $stop 100%);
}

@mixin gradient-background($color, $lighten: 30%) {
    $background-color: lighten($color, $lighten);
    background: $color-gray-lightest;
    background: -webkit-linear-gradient(to bottom, $color-gray-lightest 0, $color-gray-lightest 150px, lighten($background-color, 2%) 900px, lighten($background-color, 1%) 1000px, $background-color 1100px, lighten($background-color, 1%) 1200px, lighten($background-color, 2%) 1300px, $color-gray-lightest 2500px, $color-gray-lightest 100%);
    background: linear-gradient(to bottom, $color-gray-lightest 0, $color-gray-lightest 150px, lighten($background-color, 2%) 900px, lighten($background-color, 1%) 1000px, $background-color 1100px, lighten($background-color, 1%) 1200px, lighten($background-color, 2%) 1300px, $color-gray-lightest 2500px, $color-gray-lightest 100%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@mixin bg-opacity($color, $opacity) {
    $rgba-color: rgba(red($color), green($color), blue($color), $opacity);
    background-color: $color !important;
    background-color: $rgba-color !important;
}

@mixin theme-full($name, $default-color, $dark-color, $light-color) {

    .th-#{$name},
    .th-override.th-#{$name} {
        @include theme-classes($default-color, $dark-color, $light-color)
    }

    .th-#{$name}-color {
        color: $default-color !important;
    }

    .th-#{$name}-color-dark {
        color: $dark-color !important;
    }

    .th-#{$name}-bg-color {
        background-color: $default-color !important;
    }

    .th-#{$name}-border-color {
        border-color: $default-color !important;
    }

    .th-#{$name}-border-color-light {
        border-color: $light-color !important;
    }

    .th-#{$name}-fill-color {
        fill: $default-color !important;
    }
}

@mixin theme-classes($default-color, $dark-color, $light-color) {
    .th-bg-color {
        background-color: $default-color !important;
    }

    .th-bg-color-dark {
        background-color: $dark-color !important;
    }

    .th-bg-color-light {
        background-color: $light-color !important;
    }

    .th-border-color {
        border-color: $default-color !important;
    }

    .th-border-color-light {
        border-color: $light-color !important;
    }

    .th-fill-color {
        fill: $default-color !important;
    }
}

@mixin p-room-wide($room-bg, $room-repeat, $room-bottom) {
    background: $room-bg center -20px no-repeat, $room-repeat 0 -20px repeat-x, $room-bottom;
}

@mixin p-room-narrow($room-repeat, $room-bottom) {
    background: $room-repeat 0 -220px repeat-x, $room-bottom;
}

/* Themes */

$themes:
"p1" $p1-default $p1-dark $p1-light,
"p2" $p2-default $p2-dark $p2-light,
"p3" $p3-default $p3-dark $p3-light,
"p4" $p4-default $p4-dark $p4-light,
"junior-web" $junior-web-default $junior-web-dark $junior-web-light,
"red" $red-default $red-dark $red-light,
"green" $green-default $green-dark $green-light,
"sportgreen" $sportgreen-default $sportgreen-dark $sportgreen-light,
"ekot" $ekot-default $ekot-dark $ekot-light,
"same" $same-default $same-dark $same-light,
"sisu" $sisu-default $sisu-dark $sisu-light;

@each $theme, $theme-default, $theme-dark, $theme-light in $themes {
    .th-#{$theme},
    .th-override.th-#{$theme} {
        @include theme-classes($theme-default, $theme-dark, $theme-light)
    }

    .th-#{$theme}-color {
        color: $theme-default !important;
    }

    .th-#{$theme}-color-dark {
        color: $theme-dark !important;
    }

    .th-#{$theme}-bg-color {
        background-color: $theme-default !important;
    }

    .th-#{$theme}-border-color {
        border-color: $theme-default !important;
    }

    .th-#{$theme}-border-color-light {
        border-color: $theme-light !important;
    }

    .th-#{$theme}-fill-color {
        fill: $theme-default !important;
    }
}

body {
    @include theme-classes($no-theme-default, $no-theme-dark, $no-theme-light);
}