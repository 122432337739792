﻿.channel-page-episodes {
    @include divider-bottom-xl;
}

.channel-page-episodes:last-child {
    @include divider-bottom-xxl;
}

.channel-page__show-more {
    @include divider-top-medium;
}
