:root {
  --color-transparent: rgba(0,0,0,0);
  --color-foreground-primary-lightmode: #111111;
  --color-foreground-primary-darkmode: #ffffff;
  --color-foreground-primary-inverted-lightmode: #ffffff;

  --color-background-primary-lightmode: #ffffff;  
  --color-background-primary-darkmode: #111111;

  --color-foreground-primary: var(--color-foreground-primary-lightmode); // --color-foreground-primary-light
  --color-foreground-primary-inverted: var(--color-foreground-primary-inverted-lightmode); // --color-foreground-primary-inverted-light
  --color-foreground-secondary: #3a3a3a; // --color-foreground-secondary-light
  --color-foreground-tertiary: #808080; // --color-foreground-tertiary-light

  --color-background-primary: #ffffff; // --color-background-primary-light
  --color-background-primary-inverted: #111111; // --color-foreground-primary-inverted-dark
  --color-background-quaternary-lightmode: #f1f1f1; // --color-foreground-primary-inverted-dark
  --color-background-quaternary-darkmode: #1a1a1a; // --color-foreground-primary-inverted-dark

  --color-important: #e62243; // --color-important-light
  --color-important-inverted: #ffdb00;
  --color-highlight: #ffdb00; // --color-hightlight-light
  --color-success: #4cd964; // --color-success-light

  --color-box-shadow: rgba(0, 0, 0, .03);

  // Legacy colors that we treat with care and love
  --color-border: #{$color-gray-light};
  --color-background-light: #{$color-gray-lightest};
  --color-background-light-secondary: #{$color-gray-light};

  @include dark-mode {
    --color-foreground-primary: #ffffff; // --color-foreground-primary-dark
    --color-foreground-secondary: #808080; // --color-foreground-secondary-dark
    --color-foreground-primary-inverted: #111111; // --color-foreground-primary-inverted-dark
    --color-foreground-tertiary: #3a3a3a; // --color-foreground-tertiary-dark

    --color-background-primary: #111111; // --color-background-primary-dark
    --color-background-primary-inverted: #ffffff; // --color-foreground-primary-inverted-light
    
    --color-important: #ffdb00; // --color-highlight-dark
    --color-important-inverted: #e62243;
    --color-highlight: #ffdb00; // --color-highlight-dark
    --color-success: #4cd964; // --color-success-dark

    --color-box-shadow: rgba(255, 255, 255, .03);

    // Legacy colors that we treat with care and love
    --color-border: var(--color-foreground-tertiary);
    --color-background-light: var(--color-foreground-tertiary);
    --color-background-light-secondary: #{$color-gray-dark};
  }
}
