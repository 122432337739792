﻿.episode-details {
    @extend %component-design;
    @include inner-divider;
}

.episode-details__header {
    @include grid-gutter;
    @include inner-divider;
    @include container-narrow;
    padding-top: $space-xl;
}

.episode-details__body {
    @include inner-divider-xl;
}

.episode-details__body-item {
    @include grid-gutter;
    @include container-narrow;
    @include divider-bottom-medium;
}

.episode-details__body-item.episode-details__body-item--no-padding {
    padding: 0;
}

.episode-details__body-item {
    &.no-padding-mobile {
        padding: 0;

        @include mq-tablet-portrait {
            @include grid-gutter;
        }
    }
}

.episode-details__html {
    clear: both;
}

.rtl {
    .episode-details__header {
        padding-right: 12px;
    }
}
