.alphabetical-program-item {
    @include clickable-design;
    display: block;
    padding: $space-medium;
    text-decoration: none;
}

.alphabetical-program-item__header {
    @include flex-center;
    text-decoration: none;
}

.alphabetical-program-item__description {
    @include divider-top-half;
}

$image-mobile-size: $space-small * 7;

.alphabetical-program-item__label {
    @include body-font-bold;
}

$image-size: 72px;
$image-size-large: 108px;

.alphabetical-program-item__image {
    width: $image-size;
    border-radius: $border-radius;
    overflow: hidden;
    flex-shrink: 0;

    img {
        display: block;
        width: 100%;
    }

    @include mq-tablet-portrait {
        width: $image-size-large;
    }
}

.alphabetical-program-item__info {
    padding: 0 $space-medium;
}

.alphabetical-program-item__info-header {
    @include divider-bottom-base;
}

.alphabetical-program-item__meta {
    color: var(--color-foreground-secondary);
}
