﻿.channel-navigation {
    @include grid-gutter;
    @extend %puff-padding-top;
}

.channel-navigation__item {
    @include reset-input-styles;
    @include border-bottom;
}

.channel-navigation__link{
    display: block;
    padding: $space-medium $space-medium $space-medium 0;
}
