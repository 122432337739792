﻿@mixin container {
  max-width: $page-max-width;
  margin-left: auto;
  margin-right: auto;

  @include mq-tablet-portrait {
      &--collapse {
          padding: 0;
      }
  }
}

@mixin container-narrow {
  max-width: $container-narrow-width;
  margin-left: auto;
  margin-right: auto;
}
