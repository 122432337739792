﻿@mixin reset-input-styles {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 0;
  text-decoration: none;
  box-sizing: border-box;
}

@mixin hidden-input {
  position: absolute;
  opacity: 0;
}