%audio-heading {
    display: flex;
}

%audio-heading--mobile {
    @include h6-style;
}

%audio-heading__play {
    @include divider-right-medium;
    position: relative;
    flex-shrink: 0;

    .rtl & {
        @include divider-left-medium;
        margin-right: 0;
    }
}

%audio-heading__play-button {
    display: block;
}

%audio-heading__meta {
    @include caption-style(var(--color-foreground-secondary));
}

%audio-heading__title {
    word-break: break-word;
    flex: 0 1 auto;
}

%audio-heading__meta-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: $space-xs;

    + .audio-heading__meta-item--space {
        margin-left: $space-small;
    }

    .rtl & {
        margin-right: 0;
        margin-left: $space-xs;
    }
}
