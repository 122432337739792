﻿.play-button {
    border: 0;
    background: none;
    -webkit-appearance: none;
}

.play-button--is-playing {
    .play-icon-play-pause__play-symbol {
        display: none;
    }

    .play-icon-play-pause__pause-symbol {
        display: block;
    }

    .play-icon-play-stop__play-symbol {
        display: none;
    }

    .play-icon-play-stop__stop-symbol {
        display: block;
    }
}
