﻿$button-height: 42px;
$factbox-max-height: 120px;

.factbox:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(70% - #{$button-height});
    background-image: linear-gradient(to bottom, var(--color-transparent), var(--color-background-light) 94%);
    bottom: $button-height;

    @include mq-tablet-portrait {
        height: calc(80% - #{$button-height});
    }
}

.factbox {
    @include border-thick-left($color: $color-gray);
    @include cell-padding;
    position: relative;
    background: var(--color-background-light);
    max-height: $factbox-max-height;
    overflow: hidden;
    border-radius: $border-radius;

    > ul,
    > ol {
        > li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    > ul {
        list-style-type: disc;
    }

    > ol,
    > ul {
        padding-left: 25px;
    }

    > p:not(:last-child) {
        @include divider-bottom-medium;
    }

    a {
        text-decoration: none;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }

        &.external-link,
        &.internal-link-with-icon {
            &:before {
                content: '';
                display: inline-block;
                height: 14px;
                position: relative;
            }
        }

        &.external-link {
            &:before {
                top: 1px;
                width: 18px;
                background: url('../../content/img/frontend/external-link-l.png') no-repeat top right;
            }
        }

        &.internal-link-with-icon {
            &:before {
                top: 2px;
                width: 14px;
                margin-right: 3px;
                background: url('../../content/img/frontend/internal-link-l.png') no-repeat top right;
            }
        }
    }
}

.factbox + .factbox {
    margin-top: $space-small;
}

.factbox__title {
    @include lead-text;
    margin-bottom: $space-small;
}

.factbox__button {
    @include reset-input-styles;
    @include caption-style;
    @include border-top;
    @include cell-padding;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
    background-color: var(--color-background-light);
}

.factbox__icon {
    @include h-item;
    width: $space-medium;
    height: 10px;
}

.factbox.is-expanded {
    max-height: none;

    .factbox__button {
        display: none;
    }
}

.factbox.is-expanded:before {
    display: none;
}
