﻿$card-min-height: 160px;

.episode-grid-item {
    @include card-design;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.episode-grid-item__body {
    @include card-body;

    @include mq-tablet-portrait {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: $card-min-height;

        @supports(justify-content: space-between) {
           flex: 1;
           min-height: unset;
        }
    }
}
