﻿$card-width: 240px;

@mixin card-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding-left: $space-medium;
    margin-left: -$space-medium;

    > * {
        width: $card-width;
        flex-shrink: 0;
    }

    @include mq-tablet-portrait {
        flex-wrap: wrap;
        overflow: hidden;
        padding: 0;
        margin-left: 0;

        > * {
            width: 25%;
        }
    }
}
