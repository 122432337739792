﻿.track-list ul + ul{
    @include border-top;
}

.track-list__show-more {
    margin: $space-medium 0;
}

.track-contender-title {
    @include cell-padding;
    @include border-bottom;
    @include border-top;
    padding-top: $space-xl;
}

.track-info {
    @include flex-center;
    @include cell-padding;
}

.track-details {
    @include cell-padding-horizontal;
}
