﻿.sr-icon {
    @include v-center-parent;
    height: 100%;
    display: block;
    text-align: center;
}

.sr-icon--inline {
    display: inline;
}

.sr-icon__image {
    @include v-center-child;
}
