﻿.audio-heading {
    @extend %audio-heading;
}

.audio-heading--mobile {
    @extend %audio-heading--mobile;
}

.audio-heading__subtitle {
    text-transform: uppercase;
}

.audio-heading__play {
    @extend %audio-heading__play;
}

.audio-heading__play button {
    @extend %audio-heading__play-button;
}

.audio-heading__play--inactive {
    @extend %audio-heading__play-button;
}

.audio-heading__meta {
    @extend %audio-heading__meta;
}

.audio-heading__title {
    @extend %audio-heading__title;
}

.audio-heading__meta-item {
    @extend %audio-heading__meta-item;
}
