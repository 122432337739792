@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';

































































































































































































































.message {
    @include cell-padding-vertical;
}

.inner {
    position: relative;
}

section {
    @include cell-padding-vertical;
}

.section {
    @include cell-padding-horizontal;
}

section + section {
    @include border-top;
}

.section-center {
    @include cell-padding-horizontal;
    text-align: center;
}

.loading-wrapper {
    @include cell-padding-vertical;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $space-small * 6;
    pointer-events: none;
    background-image: linear-gradient(var(--color-background-primary), $color-white-transparent);
}

.clear-button {
    @include reset-input-styles;
    @include cell-padding-vertical-base;
    @include h6-style;
    padding-left: 0;
    padding-right: 0;
    margin-top: -$space-medium;
    font-weight: normal;
    color: $color-gray;
}

.show-all-link {
    @include button;
    width: 100%;
}

a {
    @include cell-padding-vertical-base;
}

.icon {
    @include divider-right-base;
}

footer {
    @include cell-padding;
    @include border-top;
    display: none;
    text-align: center;
}

.loading {
    opacity: 0;
}

@include mq-tablet-portrait {
    section:first-child {
        padding-top: $space-medium;
    }

    .inner {
        position: relative;
        z-index: $z-index--search-container;
    }

    section {
        padding-top: $space-medium;
    }

    .flex-header {
        @include inner-divider-horizontal;
        justify-content: flex-start;
        align-items: baseline;
    }

    .show-all-link {
        width: auto;
    }

    footer {
        display: block;
    }
}
