﻿.promotion-wrapper {
  @include divider-bottom-medium;
}

.promotion {
  @include card-design;
  overflow: hidden;

  @include mq-desktop {
    display: flex;

    > * {
      flex: 1;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    margin: 0;
  }

  .promotion__iframe {
    @extend %image-crop;
  }

  .publication-theme {
    padding-left: 0;
    padding-top: 0;
  }

  header {
    @include grid-gutter;
    @include cell-padding;
    @include border-thick-bottom($width: $space-small);
    position: relative;

    @include mq-tablet-portrait {
      @include flex-center;
      @include border-thick-right($width: $space-small);
      border-bottom: 0;
    }
  }
}

.promotion__header-inner {
  width: 100%;
}

.promotion__preamble {
  @include divider-top;
}
