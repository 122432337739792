.section-header-component {
    @include border-header;
    @include flex-items-on-sides(baseline);
}

.section-header-component a {
    flex-shrink: 0;
    margin: -$space-medium;

    @include mq-tablet-portrait {
        @include divider-left-medium;
    }
}
