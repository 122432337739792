.info-teaser-item {
    display: flex;
    padding: $space-medium;
    text-decoration: none;

    h6{
        display: inherit;
    }
}

.info-teaser-item--audio {
    @include reset-input-styles;
    @include flex;
    font-weight: inherit;
    text-align: left;

    @include mq-tablet-portrait {
        @include flex-center;
    }
}

.info-teaser-item__play {
    @include divider-right-base;
}

@include mq-tablet-portrait {
    .info-teaser-item__play {
        margin-right: $space-small;
    }
}
