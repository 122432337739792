﻿@mixin ellipsis-wrapper {
    min-width: 0;
    overflow: hidden;
}

@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    > * {
        display: inline;
    }
}

@mixin unset-ellipsis {
    white-space: unset;
    text-overflow: unset;
    overflow: visible;
}
