﻿$image-width: $space-small * 13;
$image-width-large: $space-small * 20;

.puff-small {
    @include cell-padding;
    @include flex();

    @include mq-mobile-large {
        align-items: center;
    }
}

.puff-small__header {
    @include divider-right-medium;
    flex: 1;
}

.puff-small__image {
    @include border-radius;
    overflow: hidden;
    width: $image-width;
}

.puff-small__image img {
    display: block;
    width: 100%;
}
@include mq-desktop-large {
    .puff-small__image {
        width: $image-width-large;
    }
}
