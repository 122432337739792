$menu-min-height: 56px;
$menu-min-height-desktop: 73px;
$channel-aside-module-min-height: 634px;
$compact-channels-min-height: 122px;
$compact-channels-start-page-min-height: 102px;
$unit-header-submenu-height: $space-small*6;
$unit-header-submenu-height-large: $space-small*7;
$compact-menu-height: 56px;
$program-menu-height: 280px;

.global-fixed-height--main-menu {
    min-height: $menu-min-height;

    @include mq-desktop {
        min-height: $menu-min-height-desktop;
    }
}

.global-fixed-height--main-menu-secondary {
    min-height: $menu-min-height;
}

.global-fixed-height--compact-channels {
    min-height: $compact-channels-min-height;
}

.global-fixed-height--compact-channels-start-page {
    min-height: $compact-channels-start-page-min-height;
}

.global-fixed-height--unit-header-submenu {
    height: $unit-header-submenu-height;

    @include mq-tablet-portrait {
        height: $unit-header-submenu-height-large;
    }
}

.global-fixed-height--compact-menu {
    min-height: $compact-menu-height;
}
