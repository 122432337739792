:root {
  font-size: $root-element-size;
}

* {
  box-sizing: border-box;
}

body {
  @include body-text;
  position: relative;
  background: var(--color-background-primary);
  color: var(--color-foreground-primary);
  -webkit-font-smoothing: antialiased;
}

main {
  display: block;
}

a {
  @include default-link;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

body:not(.js-on) .lazyload {
  display: none;
}

.cookie-popover-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: $z-index--cookie-popover;
}

@mixin show-with-flex {
  display: flex;
}

.hide-xs {
  display: none;

  @include mq-mobile-medium {
    display: block;

    &.col-flex {
      @include show-with-flex;
    }
  }
}

.hide-sm {
  @include mq-mobile-medium {
    display: none;
  }

  @include mq-tablet-portrait {
    display: block;

    &.col-flex {
      @include show-with-flex;
    }
  }
}

.hide-md {
  @include mq-tablet-portrait {
    display: none;
  }

  @include mq-desktop-large {
    display: block;

    &.col-flex {
      @include show-with-flex;
    }
  }
}

.player-container {
  position: relative;
  z-index: $z-index--player;
}

.has-border-radius {
  @include border-radius;
}

.has-gradient-transparent-to-black {
  @include scrimGradient($color-black-transparent, 'to top');
}

.has-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > * {
    display: inline;
  }
}

.is-multiline-tablet {
  @include mq-tablet-portrait {
    flex-wrap: wrap;
  }
}
