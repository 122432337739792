.offscreen {
  position: absolute;
  left: -999em;
  top: -999em;
}

/* clearfix */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  height: 1%;
}

/* do not remove: there are two almost identical rules for a purpose */
.clearfix {
  display: inline-block;
}
/* do not remove: there are two almost identical rules for a purpose */
.clearfix {
  display: block;
}

.hidden {
  display: none !important;
}

.hide-for-tablet,
.hide-for-mobile {
  position: absolute;
  left: -999em;
}

.hide-for-desktop {
  position: static;
}

@include mq-mobile-large {
  .hide-for-mobile {
    position: static;
  }
}

@include mq-tablet-portrait {
  .hide-for-tablet {
    position: static;
  }

  .hide-for-desktop {
    position: absolute;
    left: -999em;
  }
}
