﻿.audio-heading-label {
    @include caption-style-small;
    display: inline-flex;
    align-items: center;
    padding: $space-xs;
    border-radius: $border-radius;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-white;
    background-color: $color-gray;
}
