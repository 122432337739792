@use "sass:math";

// Variables
@import '../../../../style/variables/spacing';
@import '../../../../style/variables/dimensions';
@import '../../../../style/variables/border-variables';
@import '../../../../style/variables/colors';
@import '../../../../style/variables/z-indexes';
@import '../../../../style/variables/easings';

// Typography
@import '../../../../style/typography/fonts';

// Mixins
@import '../../../../style/mixins/display';
@import '../../../../style/mixins/background';
@import '../../../../style/mixins/media-queries';
@import '../../../../style/mixins/grid-gutters';
@import '../../../../style/mixins/padding';
@import '../../../../style/mixins/dividers';
@import '../../../../style/mixins/borders';
@import '../../../../style/mixins/border-header';
@import '../../../../style/mixins/flex';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/icon';
@import '../../../../style/mixins/puff-common';
@import '../../../../style/mixins/image';
@import '../../../../style/mixins/box-shadow';
@import '../../../../style/mixins/card';
@import '../../../../style/mixins/card-list';
@import '../../../../style/mixins/component-design';
@import '../../../../style/mixins/container';
@import '../../../../style/mixins/clickable-design';
@import '../../../../style/mixins/button';
@import '../../../../style/mixins/link';
@import '../../../../style/mixins/thumbnail';
@import '../../../../style/mixins/audio-heading';
@import '../../../../style/mixins/full-page-style';
@import '../../../../style/mixins/iframe';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/ellipsis';
@import '../../../../style/mixins/focus';
@import '../../../../style/mixins/gradient';

// Ui
@import '../../../../style/ui/ui-section-header';
@import '../../../../style/ui/ui-show-more-button';




























$handle-dimension: $space-small * 6;
$handle-dimension-hover: $space-xl;

.handle {
    @include flex-v-h-center;
    position: absolute;
    top: 50%;
    width: $handle-dimension;
    height: $handle-dimension;
    margin-top: -(math.div($handle-dimension, 2));
    margin-left: -(math.div($handle-dimension, 2));
    transition: transform 0.15s ease, opacity 0.15s ease;
    opacity: 0;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--color-background-primary-inverted);
        opacity: 0.25;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.15s ease, opacity 0.15s ease;
        width: 100%;
        height: 100%;
    }
}

.active:before {
    transform: translate(-50%, -50%) scale(1.2);
}

.active .dot {
    transform: scale(1.2);
}

.dot {
    position: relative;
    width: $space-medium;
    height: $space-medium;
    border-radius: 50%;
    background-color: var(--color-background-primary-inverted);
    transition: transform 150ms ease;
}
