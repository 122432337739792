﻿.chronologic-flow {
    @include border-top;
    position: relative;
}

.chronologic-flow__inner {
    @extend %component-design;
    margin: 0;
    overflow: visible;
}

.chronologic-flow-day {
    padding-left: $space-medium;
    position: relative;
}

.chronologic-flow-day__header {
    @include cell-padding-vertical;
    @include border-thick-left($width: 2px);
    @include flex-center;

    &:before {
        @include divider-right-base;
        display: block;
        content: '';
        width: $space-small;
        height: $space-small;
        border-radius: $border-radius-button;
        background-color: $color-black;
        margin-left: -$space-xs;
    }
}

.chronologic-flow-day__list-item {
    border-left: 2px solid $color-gray-lightest;
    border-top: 0;
}

.chronologic-flow-day__list-item + .chronologic-flow-day__list-item .puff-small {
    @include border-top;
}

.chronologic-flow-day__list-item + .chronologic-flow-day__list-item {
    border-top: 0;
}

.chronologic-flow-day__list-item:last-child {
    @include border-bottom;
}

.chronologic-flow__show-more {
    @include grid-component-margin;
    @include divider-top-medium;
}

@include mq-tablet-portrait {
    .chronologic-flow__header {
        margin-bottom: 20px;
    }
}
