﻿$show-more-height: $space-small * 7;

.puff-flow {
    .legacy-main-content ~ & {
        @include divider-top-medium;
    }
}

.puff-flow__item:not(.puff-flow__item--transparent) {
    @include card-design;
}

.puff-flow__item + .puff-flow__item {
    @include divider-top-medium;
}

.puff-flow__item--transparent {
    @include reset-gutter;
    @include cell-padding-vertical;
    background: none;
    
    @include mq-tablet-portrait {
        margin: 0;
    }
}

.right-puff-flow__item {
    @include card-design;
    @include divider-bottom-medium;
}

.puff-flow__header {
    + .puff-flow__item {
        @include divider-top-medium;
    }
}

.puff-flow__show-more {
    @include card-box-shadow;
    @include divider-top-medium;
    border-radius: $border-radius;
    overflow: hidden;

    &.fetching {
        @include ui-show-more-button;
        height: $show-more-height;
    }

    @include mq-tablet-portrait {
        margin-left: 0;
        margin-right: 0;
    }
}

@keyframes rotate-plus-sign {
    from {
        transform: scale(1) rotate(0deg);
    }

    100% {
        transform: scale(1.2) rotate(90deg);
    }
}

.puff-flow__show-more:hover {
    i {
        animation: rotate-plus-sign 0.35s 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
    }
}

@media (pointer: coarse) {
    .puff-flow__show-more i {
        animation: none;
    }
}

@include mq-tablet-portrait {
    .puff-flow__show-more {
        margin-bottom: $space-medium;
    }
}
