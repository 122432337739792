@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




















































































































































































$textarea-min-height: 62px;

.textarea {
    @include border-radius;
    @include border;
    @include flex(stretch);
}

.share-url-textarea {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.textarea button {
    @include cell-padding;
}

button {
    @include reset-input-styles;
    @include flex-center;
    @include cell-padding-vertical;
    padding-left: 0;
    padding-right: 0;
    color: var(--color-foreground-primary);
}

svg {
    @include divider-right-base;
    width: $space-large;
    height: $space-large;
    flex-shrink: 0;
}

textarea {
    @include reset-input-styles;
    @include cell-padding;
    appearance: none;
    width: 100%;
    border: 0;
    background-color: $color-gray-lightest;
    resize: none;
}

.embed-textarea {
    min-height: $textarea-min-height;
    resize: vertical;
}

.share-body {
    @include cell-padding;
    @include inner-divider-base;
}

.share-links {
    @include flex;
    @include flex-wrap;
}

.share-links > * {
    width: 50%;

    @include mq-tablet-portrait {
        width: 20%;
    }
}
