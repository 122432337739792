@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';

















































































































@include mq-tablet-portrait {
    .main-menu-list {
        @include border-top;
    }
}

.main-menu-list section {
    @include border-top;
    @include cell-padding;
    padding-bottom: $space-medium;
    flex-shrink: 0;
}

section:last-child {
    border: 0;
    padding-top: 0;
}

section:nth-child(3) {
    padding-bottom: 0;
}

footer {
    @include border-top;
    @include cell-padding;
}

footer button {
    width: 100%;
}

@include mq-desktop-large {
    section:first-child {
        display: none;
    }
}

@include mq-tablet-portrait {
    footer {
        display: flex;
        justify-content: center;
    }

    footer button {
        width: auto;
    }

    .main-menu-list__title {
        min-height: $space-medium;
    }

    .main-menu-list section {
        padding: $space-xl 0;
        border: 0;
    }
}
