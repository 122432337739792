﻿.share-inline__list {
    .share-inline__button {
        margin: 0 $space-small 0 0;
        display: inline-block;
        vertical-align: top;
    }
}

.rtl {
    .share-inline__list {
        .share-inline__button {
            margin: 0 0 12px 6px;
        }
    }
}
