@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';



























































































































































































$modal-button-max-width: $space-small * 168;
$modal-image-max-width: $space-small * 150;
$modal-content-padding: $space-small * 9 0;
$icon-width: $space-small * 6;
$icon-height: $icon-width;
$live-icon-width: 56px;

.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: $space-medium;

    @include mq-mobile-small {
        padding: $space-medium $space-xl;
    }

    @include mq-tablet-portrait {
        display: block;
        height: auto !important;
    }

    @include mq-desktop-large {
        padding: {
            left: 0;
            right: 0;
        }
        margin: 0;
    }
}

.header {
    @include mq-tablet-portrait {
        @include cell-padding-horizontal;
    }
}

.header-inner {
    @include mq-tablet-portrait {
        justify-content: space-between;
        text-align: center;

        > * {
            flex: 1;
        }

        &:before {
            display: block;
            content: '';
            flex: 1;
        }
    }
}

.playlist-title {
    @include mq-tablet-portrait {
        text-align: center;
    }
}

.time,
.player-slider {
    @include divider-top-medium;
}

.time {
    @include mq-tablet-portrait {
        @include divider-top-xl;
    }
}

.meta {
    flex: 1;
}

.queue-progress {
    margin: 0;
}

.wrapper {
    overflow: hidden;
}

.modal {
    @include inner-divider;
    overflow: hidden;
    background-color: var(--color-background-primary);

    @include mq-tablet-portrait {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

.main {
    @include divider-bottom-medium;
}

.queue {
    @include divider-bottom-medium;
}

.controls {
    margin-top: $space-xl;
}

.scroll-to-settings {
    @include mq-tablet-portrait {
        display: none;
    }
}

.settings {
    @include border-top;
    justify-content: center;
    padding: 0 $space-medium;
    margin-right: -$space-small;

    @include mq-mobile-small {
        padding: 0 $space-xl;
    }

    @include mq-tablet-portrait {
        margin: 0;
        border: 0;
        justify-content: space-between;
    }
}

.content {
    position: relative;
    z-index: $z-index--modal-content;
}

.body {
    @include inner-divider;
}

.close-button {
    @include reset-input-styles;
    @include cell-padding;
    @include inner-divider-horizontal-half;
    display: flex;
    justify-content: flex-end;
    margin: 0 (-$space-medium) 0;
    color: var(--color-foreground-primary);

    &:first-child {
        margin-left: auto;
    }
}

.settings-inner {
    @include mq-tablet-portrait {
        @include flex-items-on-sides;
        align-items: center;
    }
}

.live-label {
    @include mq-desktop-large {
        margin-right: -$live-icon-width;
    }
}

footer {
    position: relative;
}
