﻿@mixin padding-top {
  padding-top: $space-medium;
}

@mixin page-padding-top {
  padding-top: $space-xl;
}

@mixin cell-padding {
  padding: $space-medium;
}

@mixin cell-padding-base {
  padding: $space-small;
}

@mixin cell-padding-horizontal {
  padding-left: $space-medium;
  padding-right: $space-medium;
}

@mixin cell-padding-vertical {
  padding-top: $space-medium;
  padding-bottom: $space-medium;
}

@mixin cell-padding-vertical-medium {
  padding-top: $space-medium;
  padding-bottom: $space-medium;
}

@mixin cell-padding-vertical-base {
  padding-top: $space-small;
  padding-bottom: $space-small;
}
@mixin cell-padding-horizontal-base {
  padding-left: $space-small;
  padding-right: $space-small;
}

@mixin cell-padding-half {
  padding: $space-small;
}