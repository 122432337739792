﻿$circle-width: 6px;
$circle-border-width: 2px;

.live-label {
    @include live-label-text-style;
    display: inline-flex;
    align-items: center;
    border-radius: $border-radius;
    line-height: 1;
    color: $color-white;
    background-color: $color-red;
    padding: $space-xs;

    &:before {
        @include border-radius;
        @include divider-right-xs;
        content: '';
        display: block;
        width: $circle-width;
        height: $circle-width;
        background-color: $color-white;
        border: $circle-border-width solid transparentize($color-red, 0.5);
        border-radius: 50%;
    }
}
