﻿.support-info {
    @include card-design;
    @include grid-gutter;
    @include cell-padding-vertical;
    margin: $space-medium;

    @include mq-tablet-portrait {
      margin: {
        left: 0;
        right: 0;
      }
    }
}

.support-info__icon {
    @include divider-right-small;
    float: left;
    vertical-align: top;
    width: 48px;
    height: 49px;
}

@include mq-tablet-portrait {
    .support-info {
        display: flex;
        align-items: center;
    }

    .support-info__icon {
        @include divider-right-medium;
        float: none;
    }

    .support-info__link {
        margin: 0 0 0 auto;
        vertical-align: middle;
    }

    .support-info__text-wrapper {
        @include divider-right-medium;
    }
}
