@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';


















































$input-padding: $space-medium;
$track-color: $color-gray-light;
$thumb-color: var(--color-foreground-primary);

$thumb-radius: $space-medium;
$thumb-height: $space-medium;
$thumb-width: $space-medium;
$thumb-shadow-size: $border-width;
$thumb-height-large: $space-large;
$thumb-width-large: $space-large;
$thumb-shadow-blur: $border-width;
$thumb-shadow-color: #111;
$thumb-border-width: $border-width;
$thumb-border-color: white;

$track-width: $space-small * 10;
$track-width-wide: $track-width * 2;
$track-height: $space-xs;
$track-shadow-size: 2px;
$track-shadow-blur: 2px;
$track-shadow-color: #222;
$track-border-width: $border-width;
$track-border-color: black;

$track-radius: $border-radius;
$contrast: 5%;

form {
    @include flex-center;
    @include inner-divider-horizontal-base;
}

@mixin track {
    width: $track-width;
    height: $track-height;
    cursor: pointer;
    animate: 0.2s;
}

@mixin thumb {
    height: $thumb-height;
    width: $thumb-width;
    border-radius: $thumb-radius;
    background: $thumb-color;
    cursor: pointer;
    border: 1px solid $color-gray;
}

input[type='range'] {
    -webkit-appearance: none;
    padding: $input-padding 0;
    width: $track-width;
    background: none;

    &.wide {
        width: $track-width-wide;
    }

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        @include track;
        background: $track-color;
        border-radius: $track-radius;

        @include dark-mode {
            background: var(--color-foreground-tertiary);
        }
    }

    &::-webkit-slider-thumb {
        @include thumb;
        -webkit-appearance: none;
        margin-top: math.div($track-height, 2) - math.div($thumb-height, 2);
    }

    &:focus::-webkit-slider-runnable-track {
        background: $track-color;

        @include dark-mode {
            background: var(--color-foreground-tertiary);
        }
    }

    &::-moz-range-track {
        @include track;
        background: $track-color;
        border-radius: $track-radius;

        @include dark-mode {
            background: var(--color-foreground-tertiary);
        }
    }

    &::-moz-range-thumb {
        @include thumb;
    }

    &::-ms-track {
        @include track;
        background: transparent;
        border-color: transparent;
        border-width: $thumb-width 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        border-radius: $track-radius * 2;
    }

    &::-ms-fill-upper {
        background: $track-color;
        border-radius: $track-radius * 2;

        @include dark-mode {
            background: var(--color-foreground-tertiary);
        }
    }

    &::-ms-thumb {
        @include thumb;
    }

    &:focus::-ms-fill-lower {
        background: $track-color;

        @include dark-mode {
            background: var(--color-foreground-tertiary);
        }
    }
}
