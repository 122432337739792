.article-details {
    @extend %component-design;
    overflow: hidden;
}

.article-details__inner {
    @include container-narrow;
}

.article-details__header-primary {
    @include grid-gutter;
    @include divider-bottom-medium;
}

.article-details__header-primary:first-child {
    padding-top: $space-medium;
}

.article-details__meta {
    @include divider-bottom-base;
    display: flex;
    justify-content: space-between;
}

.article-details__publishing-date {
    padding-top: $space-medium;
}

.article-details__section {
    @include grid-gutter;
    @include divider-bottom-medium;

    @include mq-tablet-portrait {
        @include divider-bottom-xl;
    }

    &.article-details__section--no-padding {
        padding: 0;
    }

    &.article-details__section--no-margin {
        margin: 0;
    }

    &.no-padding-mobile {
        padding: 0;

        @include mq-tablet-portrait {
            @include grid-gutter;
        }
    }
}

.article-details__theme {
    padding: $space-small 0;
}

.article-details__footer {
    @include border-top;
    @include cell-padding;
    @include mq-tablet-portrait {
      padding: 0;
    }
}

.article-details__disclaimer-box--top {
    @include cell-padding;
}

.article-details__gallery {
    position: relative;
    overflow: hidden;
    display: block;
}
