.global-visible-sm {
    display: none;
    @include mq-mobile-large {
        display: block;
    }
}

.global-visible-md {
    display: none;
    @include mq-tablet-portrait {
        display: block;
    }
}

.global-visible-lg {
    display: none;
    @include mq-desktop-large {
        display: block;
    }
}

.global-visible-flex-sm {
    display: none;
    @include mq-mobile-large {
        display: flex;
    }
}

.global-visible-flex-md {
    display: none;
    @include mq-tablet-portrait {
        display: flex;
    }
}

.global-visible-flex-lg {
    display: none;
    @include mq-desktop-large {
        display: flex;
    }
}

.global-visible-inline-sm {
    display: none;
    @include mq-mobile-large {
        display: inline;
    }
}

.global-visible-inline-md {
    display: none;
    @include mq-tablet-portrait {
        display: inline;
    }
}

.global-visible-inline-lg {
    display: none;
    @include mq-desktop-large {
        display: inline;
    }
}
