﻿@mixin card-heading {
    @include body-font-bold;
}

@mixin card-image {
    @extend %image-crop;
    background-color: var(--color-background-light);
}

@mixin card-body {
    padding: $space-medium;
    background-color: var(--color-background-primary);
}

@mixin card-hover {
    transition: box-shadow .075s ease-in;

    &:hover {
        @include card-box-shadow;

        a {
            text-decoration: underline;
        }
    }
}

@mixin card-wrapper {
    @include card-hover;
    background-color: var(--color-background-primary);
}

//Base-styles för kort
@mixin card-design($background-color: var(--color-background-primary)) {
    @include border-radius;
    @include box-shadow;
    @include border;
    background-color: $background-color;
    overflow: hidden;
}
