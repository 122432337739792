﻿.no-javascript {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $color-light-yellow;
    overflow: hidden;
    z-index: 9000;
    padding: 19px 0;
    color: $color-black; 

    .js-on & {
        display: none;
    }

    .support-link {
        text-decoration: underline;
        color: $color-black; 
    }

}

@include mq-mobile-large {
    .no-javascript {
        .gutter {
            margin:0;
        }
    }
}
