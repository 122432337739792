﻿.newsday {
    @extend %component-design;
}

.newsday-menu {
    border: 0;
    overflow: hidden;
}

.newsday__header {
    @include cell-padding;
    @include divider-bottom-medium;
}

.newsday-category {
    .newsday-category {
        @include divider-top-medium;
    }
}

.newsday-category__header {
    @include grid-gutter;
    @include divider-bottom-medium;
}


.newsday-news {
    @include cell-padding;
    @include border-top;
    display: flex;

}

.newsday-news-time {
    @include h6-style;
    margin-right: $space-small;
    align-self: flex-start;
}

.newsday-news__audio {
    margin-right: $space-small;
    align-self: flex-start;
    flex-shrink: 0;

    .play-button {
        display: block;
    }
}

.newsday-news-body {
    a {
        display: inline;
        padding: $space-medium 0;
    }
}

.newsday-news-body__title {
    @include h6-style;
}

@include mq-tablet-portrait {
    .newsday-menu {
        @include grid-gutter;
        @include reset-gutter;
        @include border-bottom;
        height: auto;
    }

    .newsday-news-time {
        margin-right: $space-medium;
    }
}
