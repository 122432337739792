﻿$logo-header--margin-left: math.div($space-small, 2);

.logo-header {
    color: $color-white;
    text-decoration: none;
    letter-spacing: 0px;
}

.ribbon__icon + .ribbon__title .logo-header {
    margin-left: $logo-header--margin-left;
}

.ribbon__icon + .ribbon__title .logo-header--small {
    font-weight: normal;
}
