﻿.container {
    @include container;
    @include mq-tablet-portrait {
      width: calc(100% - 32px);
    }
}

.container--gutter {
    @include grid-gutter;
    @include mq-tablet-portrait {
        padding: 0;
    }
}

.container--narrow {
    @include container-narrow;
}

.container--reset-gutter {
    @include reset-gutter;
}
