﻿.program-listing__module {
    @include divider-bottom-xl;
}

.program-listing__item {
    @include card-design;
    @include divider-top-medium;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.program-listing__item__heading {
    @include card-heading;
}

.program-listing__item__body {
    @include card-body;
}

.program-listing-flow__show-more {
    @include divider-top-medium;
}

