﻿$image-width-medium: $space-small * 10;

.related-item__image,
.related-item--mobile .related-item__image {
    width: $thumbnail-width;
    height: $thumbnail-width;
}

@include mq-tablet-portrait {
    .related-item__image {
        width: $image-width-medium;
        height: auto;
    }
}
