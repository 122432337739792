$sans-serif: sans-serif;

$sr-labs: SR Lab, $sans-serif;

$fs-medium: 16px;
$lh-medium: 22px;
$fs-small: 14px;
$lh-small: 18px;
$fs-xs: 0.75rem;
$lh-xs: 14px;
$font-incrementor: 4;
$font-incrementor-large: 8;

$font-weight-heading-regular: 400;
$font-weight-heading-medium: 600;
$font-weight-body-regular: 400;
$font-weight-body-semi-bold: 600;
$font-weight-body-bold: 900;

@mixin sr-labs {
    font-family: $sr-labs;
    font-weight: normal;
}

@mixin sr-labs-medium {
    font-family: $sr-labs;
    font-weight: 500;
}
@mixin sr-labs-heavy {
    font-family: $sr-labs;
    font-weight: 600;
}

@mixin responsive-font($font-size, $line-height, $increment: $font-incrementor) {
    font-size: $font-size;
    line-height: $line-height;

    @include mq-mobile-large {
        font-size: $font-size + $increment;
        line-height: $line-height + $increment;
    }
}

@mixin h1-size {
    font-size: 40px;
    line-height: 46px;
}

@mixin h2-size {
    font-size: 32px;
    line-height: 40px;
}

@mixin h3-size {
    font-size: 28px;
    line-height: 34px;
}

@mixin h4-size {
    font-size: 24px;
    line-height: 30px;
}

@mixin h5-size {
    font-size: 20px;
    line-height: 24px;
}

@mixin h6-size {
    font-size: 16px;
    line-height: 20px;
}

@mixin body-text-size {
    font-size: 16px;
    line-height: 20px;
}

@mixin caption-size {
    font-size: 16px;
    line-height: 20px;
}

@mixin heading-font-regular {
    font-weight: $font-weight-heading-regular;
}

@mixin body-font-regular {
    font-weight: $font-weight-body-regular;
}

@mixin body-font-semi-bold {
    font-weight: $font-weight-body-semi-bold;
}

@mixin body-font-bold {
    font-weight: $font-weight-body-bold;
}

@mixin overline-1-size {
    font-size: 18px;
    line-height: 22px;
}

@mixin overline-2-size {
    font-size: 12px;
    line-height: 15px;
}

@mixin button-text-size {
    font-size: 18px;
    line-height: 24px;
}

@mixin overline-base-style {
    @include body-font-bold;
    font-family: $sr-labs;
    color: var(--color-foreground-primary);
    text-transform: uppercase;
}

@mixin overline-1-style {
    @include overline-base-style;
    @include overline-1-size;
}

@mixin overline-style {
    @include overline-base-style;
    @include responsive-font($fs-small, $lh-small);
}

@mixin overline-style-small {
    @include overline-base-style;
    @include responsive-font($fs-xs, $lh-xs);
    @include mq-desktop {
        font-size: 16px;
        line-height: 20px;
    }
}

@mixin caption-style($color: var(--color-foreground-primary)) {
    font-size: $fs-medium;
    line-height: $lh-medium;
    font-family: $sr-labs;
    font-weight: $font-weight-body-regular;
    color: $color;
}

@mixin caption-style-strong {
    @include body-font-semi-bold;
}

$caption-small-font-size: 12px;

@mixin caption-style-small {
    font-family: $sr-labs;
    font-size: $caption-small-font-size;
    font-weight: $font-weight-body-regular;
    line-height: $lh-xs;
}

@mixin menu-text-style($color: $color-black, $weight: $font-weight-body-regular) {
    font-size: $fs-medium;
    line-height: $lh-medium;
    font-family: $sr-labs;
    font-weight: $weight;
    color: $color;
}

@mixin button-text-style($color: $color-black) {
    @include sr-labs-heavy;
    @include button-text-size;
    color: $color;
}

@mixin body-text {
    font-size: 16px;
    line-height: 20px;
    font-family: $sr-labs;
    font-weight: $font-weight-body-regular;

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    strong {
        @include body-font-semi-bold;
    }
}

@mixin link-style {
    text-decoration: underline;
}

@mixin lead-text {
    @include body-font-semi-bold;
    font-size: 20px;
    line-height: 25px;
    font-family: $sr-labs;
}

@mixin live-label-text-style {
    @include sr-labs-heavy;
    font-size: $fs-xs;
    line-height: 1;
}

@mixin h1-style {
    @include sr-labs-heavy;
    @include h3-size;

    @include mq-mobile-large {
        @include h2-size;
    }

    @include mq-desktop-large {
        @include h1-size;
    }
}

@mixin h2-style {
    @include sr-labs-heavy;
    @include h4-size;

    @include mq-mobile-large {
        @include h3-size;
    }

    @include mq-desktop-large {
        @include h2-size;
    }
}

@mixin h3-style {
    @include sr-labs-heavy;
    @include h5-size;

    @include mq-mobile-large {
        @include h4-size;
    }

    @include mq-desktop-large {
        @include h3-size;
    }
}

@mixin h4-style {
    @include sr-labs-heavy;
    @include h6-size;

    @include mq-mobile-large {
        @include h4-size;
    }
}

@mixin h5-style {
    @include sr-labs-heavy;
    @include h5-size;
}

@mixin h6-style {
    @include sr-labs-heavy;
    @include h6-size;
}

@mixin uppercase {
    text-transform: uppercase;
}
