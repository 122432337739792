﻿.related-audio {
    padding: $space-small $space-small $space-small 0;
}

.related-audio__play-button,
.related-audio__content {
    display: table-cell;
    vertical-align: top;
}

.related-audio__content {
    padding-left: $space-small;
}

.related-audio__play-link {
    text-decoration: none;
    display: block;
}
