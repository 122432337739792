﻿.category-module {
	@include card-design;

	.category {
        @include border-top;
		cursor: pointer;

		&.root-category {
			> a {
                @include grid-gutter;
                @include cell-padding-vertical;
                display: block;
				font-weight: bold;
			}

			&.has-sub-categories {
				position: relative;
			}
		}

		&.link-category {

			> a {
                @include grid-gutter;
                @include cell-padding-vertical;
                display: block;
			}

            > a:hover {
                text-decoration:underline;
            }
		}
	}

	.toggle-arrow {
		position: absolute;
		top: 19px;
		right: 10px;
	}
}

.category-module__header {
    @include ui-section-header;
    margin: 0;
}

.category-module__toggle-link {
    @include grid-gutter;
    @include cell-padding-vertical;
    position: relative;
    display: block;
    text-decoration: none;
}

.category-module__sublist {
    @include border-top;
}

.category-module__toggle-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: $space-medium;
    margin-top: -(math.div($space-small, 2));
    width: $space-small;
    height: $space-small;
}

.zon-a-link-list {
    @include grid-component-margin;
}

.two-col__aside--top {
    .zon-a-link-list .aside-module:first-child {
        border-top: none;
        border-bottom: 3px solid;
    }

    .category-module {
        .item {
            .toggle-hidden {
                background-color: $color-white;
            }
        }

        .category:first-child {
            border-top: 0;
        }

        .toggle-arrow {
            top: 19px;
            right: 15px;
        }
    }
}
