@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




































$total-columns: 4;

@mixin grid-container {
    display: flex;
    flex-wrap: wrap;
}

@mixin grid-columns($columns: 2) {
    > * {
        width: percentage(math.div(1, $columns));
    }
}

@for $i from 1 through $total-columns {
    .grid--col-small-#{$i} {
        @include grid-columns($i);
    }
}

@include mq-tablet-portrait {
    @for $i from 1 through $total-columns {
        .grid--col-medium-#{$i} {
            @include grid-columns($i);
        }
    }
}

@include mq-desktop-large {
    @for $i from 1 through $total-columns {
        .grid--col-large-#{$i} {
            @include grid-columns($i);
        }
    }
}

.grid {
    @include cell-padding-horizontal;
    @include grid-container;
}

.grid--collapse {
    padding: 0;
}
