$footer__logo--height: 48px;
$footer__logo--width: 260px;
$footer__link--margin: $space-small;

.global-footer {
    @include grid-gutter;
    @include border-top;
    text-align: center;
    overflow: hidden;
}

.global-footer__back-to-top {
    @include divider-bottom-large;
}

.global-footer__copyright,
.global-footer__editor {
    text-transform: uppercase;
}

.global-footer__copyright {
    margin: 0 0 $space-medium;
}

.global-footer__logo-wrapper {
    clear: both;
    display: block;
    padding: $space-xl 0;

    > i {
        display: inline-block;
    }
}

.global-footer__logo {
    display: block;
    height: $footer__logo--height;
    margin: 0 auto;
    width: $footer__logo--width;
}

.global-footer__link {
    @include h6-style;
    display: inline-block;
    text-decoration: none;
    padding: $footer__link--margin;
}

.global-footer__link--thin {
    font-weight: normal;
    white-space: nowrap;
    text-decoration: underline;
}

.global-footer__legal-info {
    padding: $space-xl $space-medium $space-xxl;
}

@include mq-tablet-portrait {
    .global-footer__link:hover {
        text-decoration: underline;
    }

    .global-footer__legal-info__primary-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $space-medium;
    }

    .global-footer__legal-info__separator {
        display: block;
        flex-shrink: 0;
        width: $space-small;
        height: $space-small;
        border-radius: 50%;
        margin: 0 $space-medium;
    }

    .global-footer__copyright {
        text-align: right;
        margin: 0;
    }

    .global-footer__editor {
        text-align: left;
    }
}
