@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';









































































































































































figure {
    @include border-radius;
    @include card-image;
    overflow: hidden;
}

.channel-meta {
    min-width: 0;
    margin-left: $space-small;

    @include mq-desktop-large {
        margin-left: $space-medium;
    }
}

.channel-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $space-small;

    @include mq-desktop-large {
        padding: $space-medium;
    }
}

.schedule-link {
    @include caption-style;
    @include reset-gutter;
}

.channel-card .program-image {
    transition: transform 0.25s ease;
}

.channel-card:hover .program-image {
    transform: scale(1.1);
}

.change-channel-button {
    @include reset-input-styles;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    display: none;

    @include mq-desktop-large {
        display: block;
    }

    span {
        @include caption-style;
        @include border($color-gray-light);
        @include box-shadow-small();
        background-color: $color-white;
        border-radius: $space-xl;
        padding: 0 $space-small;
    }
}

.channel-image {
    width: 48px;
    height: 48px;
}

.timestamp {
    display: none;

    @include mq-desktop-large {
        display: inline-block;
    }
}
