﻿.vma-item {
    @include cell-padding-vertical;
    color: $color-white;

    path {
        stroke: currentColor;
    }
}

.vma-item__header {
    > svg {
        margin-right: $space-small;
        width: 16px;
        height: 22px;
        flex-shrink: 0;
    }

    @include mq-tablet-portrait {
        @include flex;
    }
}

.vma-item__icon {
    @include divider-left-xs;
    width: $space-small;
}

.vma-item a {
    color: $color-white;
}

.vma-item aside {
    @include reset-gutter;
}

.vma-item__button {
    @include reset-input-styles;
    @include caption-style;
    @include flex-center;
    padding: 0;
    color: $color-white;
    border-bottom: 1px solid currentColor;

    @include mq-tablet-portrait {
        @include divider-left-base;
    }
}


.vma-item strong {
    @include body-font-bold;
}

.vma-item__body > * {
    @include divider-top-base;
}

.vma-item {
    @include border-top($color: $color-red-dark);
}

.vma-item__heading a {
    color: currentColor;
}

.vma-item-audio {
    @include h6-style;
    @include flex-center;
    @include reset-input-styles;
    padding: $space-small 0;
    width: 100%;
}

.vma-item-audio__play + .vma-item-audio__heading {
    @include divider-left-base;
}

.vma-item-audio__heading {
    color: $color-white;
}

.vma-item__meta {
    @include divider-bottom-base;
}
