$icon-width: 48px;
$icon-height: 48px;

$icon-small-width: 36px;
$icon-small-height: 36px;

@mixin play-icon-element-properties {
    transform-origin: center center;
    transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@mixin play-icon-hidden-state {
    opacity: 0;
    transform: scale(0.6);
}

@mixin play-icon-visible-state {
    opacity: 1;
    transform: scale(1);
}

@mixin play-icon-path-ie-fix {
    > path {
        fill: $color-white;
    }
}

.play-icon {
    width: $icon-width;
    height: $icon-height;
    display: block;
}

.play-icon--small {
    width: $icon-small-width;
    height: $icon-small-height;
}

.play-icon--background {
    @supports(--color-foreground-primary: black) {
        color: var(--color-foreground-primary);
    }
    color: $color-black;
}

.play-icon--foreground {
    @supports(--color-background-primary: white) {
        color: var(--color-background-primary);
    }
    color: $color-white;
}

.play-icon--background.play-icon--is-always-lightmode {
  color: var(--color-foreground-primary-lightmode);
}

.play-icon--foreground.play-icon--is-always-lightmode {
  color: var(--color-background-primary-lightmode);
}

.play-icon--background.play-icon--is-always-darkmode {
    color: var(--color-foreground-primary-darkmode);
}

.play-icon--foreground.play-icon--is-always-darkmode {
    color: var(--color-background-primary-darkmode);
}

.play-icon--red {
    color: $color-red;
}

.play-icon--gray {
    color: $color-gray;
}

.play-icon--light-gray {
    color: $color-gray-light;
}

.play-icon {
    position: relative;
    z-index: $z-index--play-icon-live;
}

.play-icon__play-symbol,
.play-icon__pause-symbol {
    @include play-icon-element-properties;
}

.play-icon__svg {
    @include play-icon-path-ie-fix;
    position: relative;
    z-index: $z-index--play-icon;
}

.play-icon__svg > .play-icon__pause-symbol,
.play-icon__svg > path:last-of-type {
    @include play-icon-hidden-state;
}

.play-button--is-playing .play-icon__svg > .play-icon__play-symbol,
.play-button--is-playing .play-icon__svg > path:first-of-type {
    @include play-icon-hidden-state;
}

.play-button--is-playing .play-icon__svg > .play-icon__pause-symbol,
.play-button--is-playing .play-icon__svg > path:last-of-type {
    @include play-icon-visible-state;
}
