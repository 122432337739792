@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




























































































$image-size: $space-small * 5.5;
$image-size-large: $space-small * 7;

.time {
    margin-right: $space-small;
    line-height: 1;
}

.extra-broadcast-item {
    @include cell-padding-vertical;
    @include grid-gutter;
    display: flex;
}

.extra-broadcast-item__progress {
    @include flex-center;
    @include caption-style;
    line-height: 1;
    justify-content: space-between;
    margin: $space-small 0;
}

.extra-broadcast-item__progress-bar {
    padding: 0 $space-small;
    flex: 1;
}

.active {
    align-items: flex-start;
}

.extra-broadcast-item-content__metadata {
    flex-grow: 1;
    line-height: 1;
}

.extra-broadcast-item-progressbar {
    margin: $space-small 0;
}

.extra-broadcast-item-content__metaitem {
    display: flex;
}

.extra-broadcast-item-content__time {
    display: flex;
}

.extra-broadcast-item-content__heading {
    display: flex;
    margin-left: $space-xs;
}

.extra-broadcast-item__live {
    margin-right: $space-small;
    cursor: pointer;
    @include mq-tablet-portrait {
        margin-right: $space-small;
    }
}

figure {
    width: $image-size;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;

    @include mq-tablet-portrait {
        width: $image-size-large;
    }
}

img {
    width: 100%;
    display: block;
}

.description {
    margin-top: $space-xs;
}

footer {
    display: flex;
    justify-content: flex-end;
}

.extra-broadcast-item__play button {
    padding: $space-small;
    margin-top: -$space-small;
    margin-left: -$space-small;
}
