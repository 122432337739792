@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




































$border-radius: $space-xl;

.button {
    @include button-text-style;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $space-medium $space-xl;
    appearance: none;
    border: 0;
    border-radius: $border-radius;
    text-decoration: none;
    cursor: pointer;
    background: var(--color-background-primary-inverted);
    color: var(--color-foreground-primary-inverted);
}

.alignTextCenter {
    text-align: center;
}

.caps {
    text-transform: uppercase;
}

.inverted {
    background-color: var(--color-background-primary);
    color: var(--color-foreground-primary);

    svg {
        fill: currentColor;
    }
}

.full-width {
    width: 100%;
}

svg {
    width: 9px;
    height: 10px;
    fill: $color-white;
    display: block;
    margin: 0 $space-small;
}

.secondary {
    background: none;
    color: var(--color-foreground-primary);
    box-shadow: inset 0 0 0 2px currentColor;
}

.secondary.inverted {
    color: var(--color-foreground-primary-inverted);
}
