﻿.sitemap-page {
    @extend %component-design;
    @include cell-padding;

    > h1 {
        @include divider-bottom-medium;
    }

    .group {

        > h2 {
            @include h2-style;
            @include divider-bottom-medium;
            @include divider-top-medium;
            color: $color-gray;
        }

        > ul {
            @include divider-bottom-medium;
        }

        .link-item {

            > a {
                @include border-bottom($color-gray-light);
                @include cell-padding-vertical;
                display: block;
                font-weight: bold;
                text-decoration: none;
            }

            &:first-child > a {
                @include border-top($color-gray-light);
            }
        }
    }
}

@include mq-tablet-portrait {

    .sitemap-page {

        .group {
            margin-right: -25px;

            > ul {
                float: left;
                width: 100%;

                .link-item {
                    float: left;
                    width: 50%;

                    > a {
                        @include divider-right-medium;
                        min-height: 48px;
                    }

                    &:nth-child(2) > a {
                        @include border-top($color-gray-light);
                    }
                    // IE8 fix
                    &:first-child + li > a {
                        @include border-top($color-gray-light);
                    }
                }
            }
        }
    }
}

@include mq-desktop {

    .sitemap-page {

        .group {

            > ul {

                .link-item {
                    width: 25%;

                    &:nth-child(3) > a,
                    &:nth-child(4) > a {
                        @include border-top($color-gray-light);
                    }
                    // IE8 fix
                    &:first-child + li + li > a,
                    &:first-child + li + li + li > a {
                        @include border-top($color-gray-light);
                    }
                }
            }
        }
    }
}
