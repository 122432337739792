.image--default {
    @extend %image-crop;
}

.image--compact {
    @extend %image-crop-compact;
}

.image--square {
    @extend %image-crop-square;
}

.image--squaremobile {
    @extend %image-crop-square-mobile;
}

.image--coverdesktop {
    @extend %image-crop;
    @include mq-desktop {
        height: 100%;
    }
}

.image--rounded-corners {
    @include border-radius;
}
