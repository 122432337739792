﻿.sr-lnk {
    font-weight: bold;
    text-decoration: none;
}

.sr-lnk--discrete {
    font-weight: normal;

    &:hover {
        text-decoration: underline;
    }
}

.sr-lnk--underline {
    &:hover {
        text-decoration: underline;
    }
}

.sr-link__svg {
    @include h-item;
    @include divider-left-base;
    width: $space-medium;
    height: $space-medium;
}

.sr-lnk__icon {
    display: inline-block;
    width: 13px;
    height: 21px;
    margin-right: 3px;
}

.sr-lnk__icon--no-line-break {
    display: inline;
    line-height: 21px;
    white-space: pre;
}
