$z-index--cookie-popover: 1100;

$z-index--modal-button: 1005;
$z-index--modal-content: 1004;
$z-index--modal: 1003;

$z-index--player-tooltip: 1004;
$z-index--player: 1001;
$z-index--main-menu: 900;
$z-index--main-menu-backdrop: 800;
$z-index--flyout-dialog: 700;
$z-index--flyout-backdrop: 600;
$z-index--search-overlay: 200;
$z-index--date-picker: 101;
$z-index--carousel-button: 100;
$z-index--play-icon-live: 90;
$z-index--play-icon: 10;

$z-index--player-gradient: 1;
$z-index--search-container: 1;
