@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';





































































































































$header-min-height: 60px;
$branded-heading-min-height: 20px;

section {
    @include border-top;
    @include box-shadow-contrast;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: $z-index--player;
    background-color: var(--color-background-primary);
    color: var(--color-foreground-primary);
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.branded-heading {
    min-height: $branded-heading-min-height;
}

header,
.player-meta {
    @include cell-padding-vertical-base;
    padding-left: $space-medium;
    min-width: 0;
    min-height: $header-min-height;

    @include mq-mobile-large {
        padding-left: 0;
    }

    @include mq-tablet-portrait {
        padding: $space-medium 0;
    }
}

.wrapper {
    @include reset-gutter;
}

.expand {
    display: none;
}

.player-meta {
    @include reset-input-styles;
    text-align: left;
    color: var(--color-foreground-primary);
}

@include mq-mobile-large {
    .expand {
        display: flex;
        justify-content: flex-end;
    }

    .wrapper {
        margin: 0 auto;
    }

    .player-meta,
    .expand {
        flex: 1;
    }

    .flex-wrapper {
        flex-wrap: wrap;
    }

    header {
        padding-left: 0;
    }
}

.image {
    width: 80px;
    height: auto;
}

.meta-text {
    min-width: 0;
}
