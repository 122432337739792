.sr-page__header {
    position: relative;
    padding: 13px 0 $space-small 6px;
    overflow: hidden;
}

.sr-page__wrapper {
    min-width: $page-min-width;
    overflow: hidden;
    z-index: 2;
    clear: both;

    @include mq-desktop {
        overflow: hidden;
    }
}

.sr-page__content {
    @include divider-top-medium;
}

.sr-page__columns {
    clear: both;
    position: relative;
}
