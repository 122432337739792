﻿.tabla-page {
    @extend %component-design;

    min-height: 700px;

    .tp-date-picker {
        @include border;
        @include border-radius;
        position: relative;
        padding-left: $space-medium;
        background-color: var(--color-background-light);;
        right: 0;
        top: 0;
    }

    .date-picker-item {
        @include flex-center;

        > input {
            @include cell-padding;
            font-weight: bold;
            text-align: left;
            padding-left: 8px;
            width: 120px;
        }
    }

    .date-picker-item__icon {
        flex-shrink: 0;
    }

    .picker {
        right: 48px;

        .frame {
            margin: 0 -45px 0 auto;
            position: absolute;
            right: 0;
            top: 9px;
            z-index: 1000;
        }
    }

    .box:before {
        right: 18px;
        left: auto !important;
        top: -19px;
    }

    .tabla-show-more {
        @include grid-gutter;
        padding-top: $space-medium;
        padding-bottom: $space-medium;
        border-top: none !important;
    }
}

.tabla-page__header {
    @include ui-section-header;
    @include ui-section-header-compact;
}

.tabla-page__menu {
    @include cell-padding;
    padding-top: 0;

    @include mq-tablet-portrait {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: $space-medium;
    }
}

.tabla-utskrift {
    @include border-top;
    @include cell-padding;
    position: relative;
    overflow: hidden;

    .descriptions {
        .description {
            margin-top: 2px;
        }
    }

    .text {
        position: relative;
        overflow: hidden;
    }

    .text {
        margin-left: 114px;
    }

    > ul {
        clear: both;
    }
}

.programdelar {
    @include divider-top-medium;
}

.programdel + .programdel {
    @include divider-top-medium;
}

.programdel__meta-item {
    @include caption-style;

    &:after {
        content: ',';
    }

    &:last-child:after {
        @include divider-bottom-half;
        content: '';
        display: block;
    }
}

.broadcast-item__description {
    @include divider-top-half;
}

.tabla-utskrift__primary {
    @include flex;
}

.tabla-utskrift__time,
.broadcast-item__image {
    @include divider-right-medium;
}

.broadcast-item__image {
    @include border-radius;
    overflow: hidden;
}

.tabla-utskrift__time,
.broadcast-item__header {
    @include body-font-semi-bold;
}

.broadcast-item__content {
    @include flex;
}

.broadcast-item__image {
    width: $space-small * 7;
    flex-shrink: 0;
}

.broadcast-item__image img {
    width: 100%;
    display: block;
}

@include mq-mobile-large {
    .tabla-utskrift {
        .descriptions {
            margin-left: 67px;
        }

        .headline {
            min-height: 0;
        }
    }
}

@include mq-tablet-portrait {
    .tabla-page {
        .date-filter-menu {
            .collapsible-menu {
                @include divider-right-medium;
                flex: 1;
                border: none;
            }
        }

        .tp-date-picker {
            background-color: transparent;

            .picker__input {
                padding-right: 29px; // todo: ugly!
            }

            i.date {
                margin-right: 0; // todo: ugly!
            }
        }

        .date-picker-item {
            > input {
                font-weight: normal;
            }
        }

        .box:before {
            right: 18px;
            left: auto !important;
            top: -15px;
        }
    }
}

@include mq-desktop-large {
    .tabla-page {
        .date-filter-menu {
            .collapsible-menu {
                flex: auto;
            }
        }
    }
}
