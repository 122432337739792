﻿.text-archive-header {
    @include flex-center;
    @include border-bottom;
    @include grid-gutter;
    justify-content: space-between;
    padding-top: $space-medium;
    padding-bottom: $space-medium;
}

.text-archive-header__title {
    @include h3-style;
}
