@media print {
    .print--no, aside {
        display: none !important;
    }

    .print--yes,
    .print--yes *:not(.print--no) {
        display: block !important;
        height: auto !important;
    }

    .print--yes a:not(.print--no) {
        display: inline !important;
    }
}
