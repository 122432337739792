﻿$item-min-height: $space-small*7;

.episode-explorer-nav__container {
    overflow: hidden;
}

.episode-explorer-nav {
    position: relative;
    width: 100%;
    color: $color-white;
    overflow-x: scroll;
    /* Fix to remove browser outline */
    & a {
        outline: none;
    }
}

.episode-explorer-nav__list {
    @include flex-center;
}

.episode-explorer-nav__item {
    @include menu-text-style;
    @include cell-padding-vertical;
    @include grid-gutter;
    @include body-font-bold;
    display: block;
    color: $color-gray;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    min-height: $item-min-height;
}

.episode-explorer-nav__item--active,
.episode-explorer__tab[aria-selected="true"] {
    color: $color-black;
    border-bottom: 2px solid transparent;
}

@include mq-tablet-portrait {
    .episode-explorer-nav {
        overflow: auto;
    }
}
