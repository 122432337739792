﻿.alphabetical-link-list {
    @include h5-style;
    @include divider-top-medium;
    display: flex;
    flex-wrap: wrap;
    margin-left: -$space-small;

    @supports(display: grid) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);

        @include mq-mobile-large {
            grid-template-columns: repeat(12, 1fr);
        }

        @include mq-tablet-portrait {
            grid-template-columns: repeat(16, 1fr);
        }

        @include mq-desktop-large {
            grid-template-columns: repeat(20, 1fr);
        }
    }
}

.alphabetical-link-list__item {
    display: inline-block;
}

.alphabetical-link-list__link {
    padding: $space-small;
    display: block;
}
