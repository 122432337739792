h1 {
  @include h1-style;
}

h2 {
  @include h2-style;
}

h3 {
  @include h3-style;
}

h4 {
  @include h4-style;
}

h5 {
  @include h5-style;
}

h6 {
  @include h6-style;
}

.h1 {
  @include h1-style;
}

.h2 {
  @include h2-style;
}

.h3 {
  @include h3-style;
}

.h4 {
  @include h4-style;
}

.h5 {
  @include h5-style;
}

.h6 {
  @include h6-style;
}