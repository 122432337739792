﻿$image-y-axis: 9;
$image-y-axis--compact: 7.2;
$image-x-axis: 16;
$image-ratio: math.div($image-y-axis, $image-x-axis) * 100%;
$image-ratio--compact: math.div($image-y-axis--compact, $image-x-axis) * 100%;
$image-ratio--square: 100%;

@mixin image-styles {
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
    }
}

@mixin image-square-styles {
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 100%;
        display: block;
    }
}

@mixin image-crop-base-styles() {
    position: relative;
    overflow: hidden;
    height: 0;
    background-color: var(--color-background-light);
}

%image-crop {
    @include image-crop-base-styles;
    @include image-styles;
    padding-top: $image-ratio;
}

%image-crop-compact {
    @include image-styles;
    @include image-crop-base-styles;
    padding-top: $image-ratio--compact;
}

%image-crop-square {
    @include image-square-styles;
    @include image-crop-base-styles;
    padding-top: $image-ratio--square;
}

%image-crop-square-mobile {
    @include image-square-styles;
    @include image-crop-base-styles;
    padding-top: $image-ratio--square;

    @include mq-tablet-portrait {
        @include image-crop-base-styles;
        @include image-styles;
        padding-top: $image-ratio;

        img {
            width: 100%;
            height: auto;
            left: 0;
            transform: none;
        }
    }
}
