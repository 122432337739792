﻿.alphabetical-order-program-list__section {
    position: relative;
}

.alphabetical-order-program-list__letter {
    @include border-header;
    border: 0;

    @include mq-tablet-portrait {
        margin-left: $space-medium;
    }
}

.program-list__item {

    @include mq-tablet-portrait {
        @include border;
        margin: -1px;
    }

    + .program-list__item {
        @include border-top;
    }
}
