@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




































































































































.channel-item {
    @include grid-gutter;
    @include cell-padding-vertical;
}

.channel-item,
.channel-item-content {
    display: flex;
    align-items: flex-start;
}

.channel-item-content {
    flex: 0 1 100%;
}

.channel-item-content__metadata {
    line-height: 1;
    flex: 0 1 100%;
}

.channel-item-content__title {
    @include flex-center;
    margin-bottom: $space-xs;
    justify-content: space-between;
    text-transform: uppercase;

    a {
        flex: 1;
        padding: $space-medium $space-medium 0 0;
        margin-top: -$space-medium;
    }
}

.channel-item__local-button {
    @include cell-padding;
    margin: -$space-medium;
}

.channel-item__track {
    margin-top: $space-xs;
}

.channel-item-progressbar {
    flex: 1;
    margin: $space-small 0;
    padding: 0 $space-small;
}

.channel-item-content__time {
    display: flex;
}

.channel-item-content__heading {
    display: flex;
    margin-left: $space-xs;
}

.play-button {
    @include cell-padding;
    flex-shrink: 0;
    margin-top: -$space-medium;
}

.channel-item__progress {
    @include flex-center;
    @include caption-style;
    margin: $space-xs 0;
    line-height: 1;
}

.channel-image {
    width: 3.5rem;
    height: 3.5rem;
}
