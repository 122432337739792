﻿@mixin scrimGradient($startColor: $color-black, $direction: 'to bottom') {

  $scrimCoordinates: (
          0: 1,
          19: 0.738,
          34: 0.541,
          47: 0.382,
          56.5: 0.278,
          65: 0.194,
          73: 0.126,
          80.2: 0.075,
          86.1: 0.042,
          91: 0.021,
          95.2: 0.008,
          98.2: 0.002,
          100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage(math.div($colorStop, 100));
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
}
