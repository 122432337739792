﻿.publication-theme {
    @include overline-style;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--color-foreground-secondary);
    padding: $space-small $space-medium;
}

.publication-theme--is-on-image {
    padding-bottom: 0;
}
