﻿.text__block {
    color: var(--color-foreground-primary);
    text-decoration: none;
    font-weight: normal;
}

.text--small {
    @include caption-style-small;
}

strong.text__block, .text--bold {
    @include body-font-semi-bold;
}

.text--gray {
    color: $color-gray;
}
