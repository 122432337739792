.aside-module.rtl {
    .preamble {
        > i {
            float: right;
            margin: 3px 0 0 5px;
            transform: scaleX(-1);
        }
    }
}

.aside-module.ltr {
    .preamble {
        > i {
            float: left;
            margin: 5px 5px 0 0;
        }
    }
}

.aside-module {
    @extend %component-design;

    .item {
        display: block;
        margin: 0 0 $space-small;

        &:last-child {
            margin: 0;
        }

        .toggle-arrow-expand,
        .toggle-arrow-collapse {
            margin-top: 0;
        }
    }
}

.aside-module__heading {
    @include divider-bottom-base;
}

@include mq-tablet-portrait {
    .aside-module {
        .item {
            margin: 0 0 $space-medium;
        }
    }
}
