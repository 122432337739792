﻿.promotion-teaser-list__list {
    @include divider-bottom-small;

    &:first-child {
        margin-top: -$space-medium;
    }
}

.promotion-teaser-list__item {
    @include card-design;
    display: flex;
    flex: 1;
    margin: 0;

    > * {
        @include flex-child-full-height;
    }
}

@include mq-tablet-portrait {
    .promotion-teaser-list__list {
        @include divider-bottom-medium;
        @include flex(stretch);
        @include flex-wrap;
    }

    .promotion-teaser-list__item {
        margin-bottom: 0;
        flex: 1;
    }

    .promotion-teaser-list__item
    + .promotion-teaser-list__item {
        margin-left: $space-medium;
    }
}
