@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';





































































































































































.scroll-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 (-1rem);
    padding-left: 1rem;
    -ms-overflow-style: none; /* Dölj scrollbar i IE & Edge */
    scrollbar-width: none; /* Dölj scrollbar i Firefox */

    @include mq-desktop {
        margin-right: 0;
    }
}

.scroll-wrapper::-webkit-scrollbar {
    /* Dölj scrolbar för webkit-browsers */
    display: none;
}

.carousel-button {
    display: none;

    @include mq-desktop {
        @include reset-input-styles;
        @include border($color-gray-light);
        display: block;
        position: absolute;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
        border-radius: $space-xl;
        background-color: $color-white;
        z-index: $z-index--carousel-button;

        &:first-child {
            left: $space-medium;
        }
        &:last-child {
            right: $space-medium;
        }
    }

    &.has-full-hd {
        @include mq-full-hd {
            border: 0;
            background-color: $color-gray-light;

            &:first-child {
                transform: translate(-100%, -50%);
                left: -$space-medium;
            }
            &:last-child {
                transform: translate(100%, -50%);
                right: -$space-medium;
            }
        }
    }
}

.scroll-wrapper-gutter {
    width: $space-small;
    flex: 0 0 auto;
}
