﻿body.error-page {
    background-image: none;
}

.error-module {
    background-color: var(--color-background-primary);
    text-align: center;
    padding: 30px 0 12px 0;

    .error-info {
        border-top-style: solid;
        border-top-color: var(--color-border);
        border-width: 1px 0;
        margin-bottom: 35px;

        > p {
            margin: 15px 0;
        }
    }
    
    .error-button {
        display: inline-block;
        margin: 10px 5px 0 5px;
        vertical-align: middle;
    }    
}
