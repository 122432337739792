﻿$link-svg-size: $space-medium;

.link {
    text-decoration: none;
}

.link--external > * {
    @include h-item;
}

.link--external svg {
    width: $link-svg-size;
    height: $link-svg-size;
}
