﻿.track-list-item {
    @include cell-padding-vertical;
    + .track-list-item {
        @include border-top;
    }
}

.track-list-item__time-wrapper {
    @include caption-style;
    float: none;
    text-align: center;
    color: var(--color-foreground-secondary);

    span:before {
        display: inline-block;
        content: 'Kl.';
    }
}

.track-list-item__image-wrapper {
    @include thumbnail;
    float: left;
}

.track-list-item__image {
    @include border-radius;
    overflow: hidden;
}

.track-list-item__image-helper,
.track-list-item__content-wrapper {
    overflow: hidden;
}

.track-list-item__content-wrapper {
    padding-left: $space-medium;
}

.track-list-item__image {
    height: inherit;
    width: inherit;
}

.track-list-item__title,
.track-list-item__detail {
    display: block;
    margin-bottom: $space-xs;
}

.track-list-item__detail {
    @include caption-style;
    color: var(--color-foreground-secondary);
}

.track-list-item__button-wrapper {
    margin-top: $space-small;

    a {
        @include caption-style;
        padding: $space-small $space-small $space-small 0;
        color: var(--color-foreground-secondary);
        text-decoration: underline;
    }
}

.song-list {
    @extend %component-design;
    @include cell-padding;
    min-height: 700px;

    > .song-list-header {
        position: relative;

        > h1 {
            display: inline-block;
            vertical-align: top;
        }

        > .song-list-picker {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .song-list-flow {
        > li {
            @include divider-top-medium;
        }
    }

    .day > a {
        color: inherit;
    }

    .track:last-child {
        @include border-bottom;
    }
}
