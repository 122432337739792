$mobile-small: 350px;
$mobile-medium: 430px;
$mobile-large: 600px;
$mobile-landscape: 767px;
$mobile-only: 767px;
$mobile-short: 700px;
$tablet-portrait: 769px;
$desktop: 900px;
$desktop-large: 1025px;

@mixin mq-mobile-small {
  @media screen and (min-width: 350px) {
    @content;
  }
}

@mixin mq-mobile-medium {
  @media screen and (min-width: 430px) {
    @content;
  }
}

@mixin mq-mobile-large {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin mq-mobile-landscape {
  @media screen and (max-width: 767px) and (orientation: landscape) {
    @content;
  }
}

@mixin mq-mobile-only {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin mq-mobile-short {
  @media screen and (max-height: 700px) {
    @content;
  }
}

@mixin mq-tablet-portrait {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin mq-desktop {
  @media screen and (min-width: 900px) {
    @content;
  }
}

@mixin mq-desktop-large {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin mq-widescreen {
  @media screen and (min-width: 1216px) {
    @content;
  }
}

@mixin mq-full-hd {
  @media screen and (min-width: 1408px) {
    @content;
  }
}

@mixin mq-high-density {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
              only screen and (-o-min-device-pixel-ratio: 13/10),
              only screen and (min-device-pixel-ratio: 1.3),
              only screen and (min-resolution: 120dpi) {
    @content;
  }
}

@mixin dark-mode {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin mq-internet-explorer {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content
  }
}
