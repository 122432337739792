﻿.unit-episode-group-list {
    list-style: none;
}

.unit-episode-group-list__item {
    list-style: none;
    display: inline-block;
    list-style-type: none;
    margin-bottom:10px;
}

.unit-episode-group-list__item:first-child {
    margin-right: 8px;
}

.unit-episode-group-list__item + .unit-episode-group-list__item {
    margin-right: 8px;
}