﻿.episode-list-page-tabs {
  a {
    @include cell-padding;
    @include block-link;
    @include caption-style;
    @include border-thick-bottom(transparent);
  }
  .active {
    @include body-font-bold;
    border-color: currentColor;
  }
}
