﻿$play-icon-ripple-2-animation-delay: 1.5s;
$play-icon-ripple-animation-duration: 2s;

@keyframes ripple {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    5% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

@keyframes pulsate {
    from,
    to {
        transform: scale(1);
    }

    5% {
        opacity: 1;
        transform: scale(1.05);
    }

    7% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.1);
    }

    15% {
        transform: scale(1);
    }

    25% {
        transform: scale(1);
    }

    35% {
        transform: scale(1);
    }

    36% {
        transform: scale(1.05);
    }

    38% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    55% {
        transform: scale(1.02);
    }

    58% {
        transform: scale(1);
    }

    61% {
        transform: scale(1.02);
    }

    63% {
        transform: scale(1);
    }

    67% {
        transform: scale(1.02);
    }

    68% {
        transform: scale(1);
    }

    95% {
        transform: scale(1);
    }
}

.play-icon--live {
    position: relative;
}

.play-icon--live .play-icon__svg {
    position: relative;
    z-index: $z-index--play-icon-live;
}

.play-icon--live:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-background-primary-inverted);
    border-radius: 50%;
    animation: ripple $play-icon-ripple-animation-duration linear infinite;
}
