﻿.svg-sprite-icon {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
}

.svg-sprite-icon--fill-white {
    fill: $color-white;
}

.svg-sprite-icon--stroke-white {
    fill: none;
    stroke: $color-white;
}

.svg-sprite-icon--stroke-black {
    fill: none;
    stroke: $color-black;
}
