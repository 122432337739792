@use "sass:math";

// Variables
@import '../../../../style/variables/spacing';
@import '../../../../style/variables/dimensions';
@import '../../../../style/variables/border-variables';
@import '../../../../style/variables/colors';
@import '../../../../style/variables/z-indexes';
@import '../../../../style/variables/easings';

// Typography
@import '../../../../style/typography/fonts';

// Mixins
@import '../../../../style/mixins/display';
@import '../../../../style/mixins/background';
@import '../../../../style/mixins/media-queries';
@import '../../../../style/mixins/grid-gutters';
@import '../../../../style/mixins/padding';
@import '../../../../style/mixins/dividers';
@import '../../../../style/mixins/borders';
@import '../../../../style/mixins/border-header';
@import '../../../../style/mixins/flex';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/icon';
@import '../../../../style/mixins/puff-common';
@import '../../../../style/mixins/image';
@import '../../../../style/mixins/box-shadow';
@import '../../../../style/mixins/card';
@import '../../../../style/mixins/card-list';
@import '../../../../style/mixins/component-design';
@import '../../../../style/mixins/container';
@import '../../../../style/mixins/clickable-design';
@import '../../../../style/mixins/button';
@import '../../../../style/mixins/link';
@import '../../../../style/mixins/thumbnail';
@import '../../../../style/mixins/audio-heading';
@import '../../../../style/mixins/full-page-style';
@import '../../../../style/mixins/iframe';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/ellipsis';
@import '../../../../style/mixins/focus';
@import '../../../../style/mixins/gradient';

// Ui
@import '../../../../style/ui/ui-section-header';
@import '../../../../style/ui/ui-show-more-button';






























































































$title-height: 48px;

figure {
    @include divider-bottom-medium;
    @include border-radius;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    background-color: $color-gray-lightest;

    @include mq-desktop {
        margin: 0;
    }
}

.ellipsis {
    @include ellipsis;

    @include mq-tablet-portrait {
        @include unset-ellipsis;
    }
}

.music-is-playing {
    opacity: 0.1;
    background: none;
    img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.1);
        filter: blur($space-small);
    }
}

.player-card {
    overflow: hidden;
    opacity: 0.25;
    transition: opacity 500ms ease;
    @include mq-tablet-portrait {
        @include flex-center;
    }
}

.image-wrapper {
    position: relative;
    @include mq-tablet-portrait {
        width: 50%;
        flex-shrink: 0;
    }
}

.active {
    opacity: 1;
}

.body {
    @include mq-tablet-portrait {
        @include cell-padding-horizontal;
    }
}

.tags {
    @include cell-padding;
    top: 0;
    left: 0;
    position: absolute;
}

img {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
