.episode-explorer {
    @extend %component-design;
    position: relative;
}

.episode-explorer__menu {
    @include border-bottom;
    position: relative;
    background-color: $color-white;
}

.episode-explorer__section-heading {
    padding: $space-medium $space-medium 0;
}

.episode-explorer__no-episodes {
    padding: $space-medium;
}

.episode-explorer__section-heading--divider {
    &:before {
        @include border-top;
        @include divider-bottom-xl;
        content: '';
        display: block;
        width: 100%;
    }
}

.episode-explorer__section-heading-inner {
    @include flex-items-on-sides(center);
}

.episode-explorer__specific-section-heading {
    @include ui-section-header;
    margin: 0;
}

.episode-explorer__header {
    @include flex-items-on-sides(center);
    @include border-bottom;
    position: relative;
}

.episode-explorer__tab {
    display: none;
}

.episode-explorer__tab--active {
    display: block;
}
