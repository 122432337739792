$border-style: 4px solid $color-gray-light;

.legacy-main-content {
	+ .main-flow,
	+ .legacy-main-content {
		border-top: $border-style;
        clear: both;
	}
}

.main-flow {
    overflow: hidden;

    > li {
        padding: 7px 0 12px;
        border-bottom: $border-style;

        &.main-flow-heading,
        &:last-child {
            border-bottom: none;
            margin-top: 0;
            margin-bottom: 0;
        }

        &.main-flow-heading {
            border-top-style: solid;
            border-top-width: 4px;
            padding: 0;
        }
    }

    .main-flow-show-more {
        clear: left;
        margin-top: 12px;
        margin-bottom: 12px;
        border-top: none !important;

        &.fetching {
            color: $color-gray;
        }
    }
}

@include mq-tablet-portrait {

	.main-flow {
		
		> ul > li {
			padding: 13px 0 10px;
		}
	}
}
