@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




































































































































section {
    position: relative;
}

header {
    @include flex-items-on-sides;
    @include grid-gutter;
    @include divider-bottom-medium;
    align-items: center;

    @include mq-tablet-portrait {
        padding: 0;
    }
}

.heading-wrapper {
    word-break: break-word;
}

.button-wrapper {
    flex-shrink: 0;
}

.list-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: -$space-medium;

    @include mq-tablet-portrait {
        position: relative;
        overflow: visible;
        margin: 0;
    }
}

.list {
    display: flex;
    padding-left: $space-medium;
    padding-bottom: $space-medium;

    @include mq-tablet-portrait {
        margin: 0 (-$space-medium);
        padding-left: 0;
        padding: 0 $space-small;
        flex-wrap: wrap;
    }
}

.list-item {
    display: flex;
    width: 88%;
    flex-shrink: 0;
    position: relative;
    padding-right: $space-medium;

    @include mq-tablet-portrait {
        display: flex;
        width: 50%;
        max-width: none;
        padding: 0 $space-small;
        margin-bottom: $space-medium;
        width: 33.333%;
    }
}

.list--desktop-2 .list-item {
    @include mq-tablet-portrait {
        width: 50%;
    }
}

.list-item:last-child {
    padding-right: $space-medium;

    @include mq-tablet-portrait {
        padding: 0 $space-small;
    }
}

.list-item:first-child {
    @include mq-tablet-portrait {
        padding-left: $space-small;
    }
}
