$sr-page-column-main-witdh: 65%;
$sr-page-column-aside-witdh: 35%;

.sr-page__bottom {
    clear: both;
}

.two-col__main {
    @include divider-bottom-medium;
}

@include mq-tablet-portrait {
    .two-col {

        .two-col__inner {
            float: left;
            position: relative;
            width: 100%;
        }

        .two-col__main {
            float: left;
            position: relative;
            width: 60%;
        }

        .two-col__aside {
            clear: right;
            float: right;
            background: transparent;
            width: 40%;
            position: relative;
            margin-left: -4px; // Need a larger value here than border to fix issue with Zoom out
        }
    }

    .two-col__main {
        padding-right: $space-medium;
    }
}

@include mq-desktop {
    .two-col {
        .two-col__inner {
            .two-col__main {
                width: 65%;
            }

            .two-col__aside {
                width: 35%;
            }
        }
    }
}

@include mq-desktop-large {
    .two-col {
        .two-col__inner {
            .two-col__main {
                width: $sr-page-column-main-witdh;
            }

            .two-col__aside {
                width: $sr-page-column-aside-witdh;

                &.two-col__aside--bottom {
                    min-height: 1px; // Necessary to make columns appear correct.
                }
            }
        }
    }
}

.ltr {
    direction: ltr;
    text-align: left;
}

.rtl {
    direction: rtl;
    text-align: right;
}
