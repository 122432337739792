.news-playlist-carousel-skeleton__heading{
    height: 5rem;
}

.news-playlist-carousel-skeleton__scroll-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 (-1rem);
    padding-left: 1rem;

    @include mq-desktop {
        margin-right: 0;
    }
}

.news-playlist-carousel-skeleton__card {
  @include border-radius;
  overflow: hidden;
  background-color: $color-gray-lightest;
}
