@use "sass:math";

// Variables
@import '../../../../style/variables/spacing';
@import '../../../../style/variables/dimensions';
@import '../../../../style/variables/border-variables';
@import '../../../../style/variables/colors';
@import '../../../../style/variables/z-indexes';
@import '../../../../style/variables/easings';

// Typography
@import '../../../../style/typography/fonts';

// Mixins
@import '../../../../style/mixins/display';
@import '../../../../style/mixins/background';
@import '../../../../style/mixins/media-queries';
@import '../../../../style/mixins/grid-gutters';
@import '../../../../style/mixins/padding';
@import '../../../../style/mixins/dividers';
@import '../../../../style/mixins/borders';
@import '../../../../style/mixins/border-header';
@import '../../../../style/mixins/flex';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/icon';
@import '../../../../style/mixins/puff-common';
@import '../../../../style/mixins/image';
@import '../../../../style/mixins/box-shadow';
@import '../../../../style/mixins/card';
@import '../../../../style/mixins/card-list';
@import '../../../../style/mixins/component-design';
@import '../../../../style/mixins/container';
@import '../../../../style/mixins/clickable-design';
@import '../../../../style/mixins/button';
@import '../../../../style/mixins/link';
@import '../../../../style/mixins/thumbnail';
@import '../../../../style/mixins/audio-heading';
@import '../../../../style/mixins/full-page-style';
@import '../../../../style/mixins/iframe';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/ellipsis';
@import '../../../../style/mixins/focus';
@import '../../../../style/mixins/gradient';

// Ui
@import '../../../../style/ui/ui-section-header';
@import '../../../../style/ui/ui-show-more-button';




















































































































































































$progressbar-height: 4px;
$progressbar-height-hover: $progressbar-height * 2;
$handle-dimension: $progressbar-height * 4;
$handle-dimension-hover: $progressbar-height * 6;

.slider-wrapper {
    @include cell-padding-vertical;
    position: relative;
    pointer-events: none;
    margin: -$space-medium 0;

    @include mq-tablet-portrait {
        &:hover {
            &:not(.always-show-handle) {
                .slider {
                    height: $progressbar-height-hover;
                }
            }

            .handle {
                opacity: 1;
            }

            .tooltip {
                opacity: 1;
            }
        }
    }
}

.always-show-handle {
    pointer-events: initial;
    .handle {
        opacity: 1;
    }
}

.slider {
    position: relative;
    height: $progressbar-height;
    background-color: $color-gray-light;
    transition: height 0.25s ease;

    @include dark-mode {
        background-color: var(--color-foreground-tertiary);
    }

    &.fatal {
        background-color: $color-red;
    }
}

.buffer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--color-foreground-secondary);
    opacity: 0.25;
}

@include mq-tablet-portrait {
    .slider-wrapper:not(.always-show-handle) {
        pointer-events: initial;
    }
}

.rounded {
    @include border-radius;
}
