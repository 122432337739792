﻿.details-button-list--right {
    justify-content: flex-end;
    margin: 0 (-$space-medium) (-$space-medium);
}

.details-button-list--horizontal {
    display: flex;
}

.details-button-list.details-button-list--vertical {
    display: block;
}

@include mq-tablet-portrait {
    .details-button-list {
        @include flex-center;
    }
}
