.collapsible-menu {
    min-height: 42px;
    margin: $space-medium 0;
    position: relative;

    @include mq-tablet-portrait {
        display: flex;
        align-items: baseline;
        margin: 0;
        padding: 0;
    }
}

.collapsible-menu__heading {
    display: none;

    @include mq-desktop-large {
        display: block;
    }
}

.collapsible-menu__item {
    .is-collapsed & {
        display: none;

        @include mq-desktop-large {
            display: block;
        }
    }

    .is-expanded & {
        order: 1;

        &.collapsible-menu__item--active {
            @include border-bottom;
            order: 0;

            @include mq-desktop-large {
                border: 0;
            }
        }
    }

    &.collapsible-menu__item--active {
        display: block;
    }
}

.collapsible-menu__item--active {
    @include body-font-bold;
    position: relative;

    @include mq-desktop-large {
        > a {
            @include body-font-bold;
            @include border-thick-bottom(currentColor);
        }
    }
}

.collapsible-menu__item > a {
    @include cell-padding;
    display: block;
    text-decoration: none;

    @include mq-desktop-large {
        padding: {
            left: $space-small;
            right: $space-small;
        }
    }
}

.collapsible-menu__item + .collapsible-menu__item {
    @include border-top;

    @include mq-desktop-large {
        border: 0;
    }
}

.collapsible-menu > .collapsible-menu__list {
    border: 1px solid $color-gray-light; //needed because theme css class is setting a theme colored border

    @include mq-desktop-large {
        border: 0;
    }
}

.collapsible-menu__list {
    @include border-radius;
    @include card-design;
    display: flex;
    flex-direction: column;
    flex: 1;

    @include mq-desktop-large {
        flex-direction: row;
        flex-wrap: wrap;
        border: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
    }

    .is-expanded & {
        @include box-shadow;
    }
}

.collapsible-menu__icon {
    position: absolute;
    top: 22px;
    right: $space-medium;
    width: $space-medium;
    height: $space-medium;
    z-index: 1;
    transition: transform 0.1s ease;
    pointer-events: none;

    .is-expanded & {
        transform: rotate(180deg);
    }

    @include mq-desktop-large {
        display: none;
    }
}
