﻿@mixin divider-top {
  margin-top: $space-small;

  @include mq-tablet-portrait {
      margin-top: $space-medium;
  }
}

@mixin divider-top-half {
  margin-top: $space-xs;
}

@mixin divider-top-base {
  margin-top: $space-small;
}

@mixin divider-top-small {
  margin-top: $space-small;
}

@mixin divider-top-medium {
  margin-top: $space-medium;
}

@mixin divider-top-xl {
  margin-top: $space-xl;
}

@mixin divider-bottom-half {
  margin-bottom: $space-xs;
}

@mixin divider-bottom-base {
  margin-bottom: $space-small;
}

@mixin divider-bottom-small {
  margin-bottom: $space-small;
}

@mixin divider-bottom-medium {
  margin-bottom: $space-medium;
}

@mixin divider-bottom-large {
  margin-bottom: $space-large;
}

@mixin divider-bottom-xl {
  margin-bottom: $space-xl;
}

@mixin divider-bottom-xxl {
  margin-bottom: $space-xxl;
}

@mixin divider-top-xl {
  margin-top: $space-xl;
}

@mixin divider-top-xxl {
  margin-top: $space-xxl;
}

@mixin divider-right-xs {
  margin-right: $space-xs;
}

@mixin divider-right-base {
  margin-right: $space-small;
}

@mixin divider-right-small {
  margin-right: $space-small;
}

@mixin divider-right-medium {
  margin-right: $space-medium;
}

@mixin divider-left-xs {
  margin-left: $space-xs;
}

@mixin divider-left-base {
  margin-left: $space-small;
}

@mixin divider-left-small {
  margin-left: $space-small;
}

@mixin divider-left-medium {
  margin-left: $space-medium;
}

@mixin inner-divider {
    > * + * {
        @include divider-top-medium;
    }
}

@mixin inner-divider-base {
    > * + * {
        @include divider-top-base;
    }
}

@mixin inner-divider-half {
    > * + * {
        @include divider-top-half;
    }
}

@mixin inner-divider-xl {
    > * + * {
        @include divider-top-xl;
    }
}

@mixin parent-cell-divider {
    > * {
        @include divider-bottom-medium;
    }
}

@mixin inner-divider-horizontal {
    > * {
        @include divider-right-medium;
    }
}

@mixin inner-divider-horizontal {
    > * {
        @include divider-right-medium;
    }
}

@mixin inner-divider-horizontal-half {
    > * + * {
        @include divider-left-xs;
    }
}

@mixin inner-divider-horizontal-base {
    > * + * {
        @include divider-left-base;
    }
}