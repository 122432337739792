@mixin border($color: var(--color-border)) {
    border: 1px solid $color;
}

@mixin border-bottom($color: var(--color-border)) {
    border-bottom: 1px solid $color;
}

@mixin border-left($color: var(--color-border)) {
    border-left: 1px solid $color;
}

@mixin border-right($color: var(--color-border)) {
    border-right: 1px solid $color;
}

@mixin border-thick-left($color: var(--color-border), $width: $space-xs) {
    border-left: $width solid $color;
}

@mixin border-thick-right($color: var(--color-border), $width: $space-xs) {
    border-right: $width solid $color;
}

@mixin border-thick-bottom($color: var(--color-border), $width: $space-xs) {
    border-bottom: $width solid $color;
}

@mixin border-thick-top($color: var(--color-border), $width: $space-xs) {
    border-top: $width solid $color;
}

@mixin border-top($color: var(--color-border)) {
    border-top: 1px solid $color;
}

@mixin border-radius {
    border-radius: $border-radius;
}
