@mixin ui-section-header-compact {
    margin: 0;
}


@mixin ui-section-header {
    @include flex-center;
    @include border-bottom;
    @include cell-padding;
    @include divider-bottom-medium;
    justify-content: space-between;

    &.compact {
       @include ui-section-header-compact;
    }

    &.no-border {
        border: 0;
        margin: 0;
    }
}