﻿.details-button {
    @include reset-input-styles;
    @include caption-style;
    display: inline-flex;
    align-items: center;
    padding: $space-medium;
    padding-left: 0;
}

.details-button:hover {
    text-decoration: none;
    color: $color-gray;
}

.details-button__icon {
    @include icon-small;
    @include divider-right-base;
    display: block;
}

.details-button:hover g {
    stroke: $color-gray;
}

.details-button:hover path {
    fill: $color-gray;
}

.rtl {
    .details-button {
        padding-left: $space-medium;
        padding-right: 0;
    }

    .details-button__icon {
        margin-right: 0;
        @include divider-left-xs;
    }
}

.details-button__title {
    @include border-bottom(currentColor);
}

.details-button__icon--play {
    width: auto;
    height: auto;
}

.listen-later-svg-icon__checked {
    opacity: 0;
}

.listen-later--added .listen-later-svg-icon__checked {
    opacity: 1;
}

.listen-later--added .listen-later-svg-icon__default {
    opacity: 0;
}

.details-button__title--added {
    display: none;
}

.listen-later--added .details-button__title {
    display: none;
}

.listen-later--added .details-button__title--added {
    display: block;
}

.details-button__icon--listen-later {
    position: relative;

    > svg:last-child {
        opacity: 0;
    }
}

.details-button__icon--listen-later > * {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .25s ease, transform .25s ease;
}

@mixin listen-later-icon-hidden-state {
    opacity: 0;
    transform: scale(.8);
}

@mixin listen-later-icon-visible-state {
    opacity: 1;
    transform: scale(1);
}


.details-button__icon--listen-later > svg:last-child {
    @include listen-later-icon-hidden-state;
}

.listen-later--added .details-button__icon--listen-later > svg:last-child {
    @include listen-later-icon-visible-state;
}

.listen-later--added .details-button__icon--listen-later > svg:first-child {
    @include listen-later-icon-hidden-state;
}
