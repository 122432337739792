@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';





































$menu-dash-width: $space-medium;
$menu-dash-height: 2px;
$menu-dash-offset: 250%;
$button-width: $space-small * 7;

button {
    @include flex-center;
    @include reset-input-styles;
    @include cell-padding-horizontal;
    position: relative;
    align-self: stretch;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0;
}

.active {
    background-color: var(--color-background-light);
}

.active .menu-1 {
    transform: translate(0) rotate(45deg);
}

.active .menu-2 {
    opacity: 0;
}

.active .menu-3 {
    transform: translate(0) rotate(-45deg);
}

.menu-icon {
    @include flex-center;
    @include divider-left-base;
    position: relative;
    width: $menu-dash-width;
    height: $space-medium;
    flex-shrink: 0;
}

.menu {
    width: 100%;
    height: $menu-dash-height;
    background-color: var(--color-foreground-primary);
}

.menu-1,
.menu-3 {
    position: absolute;
    transition: transform 0.2s ease-in-out;
    top: 50%;
    left: 50%;
    margin-top: -(math.div($menu-dash-height, 2));
    margin-left: -(math.div($menu-dash-width, 2));
}

.menu-1 {
    transform: translateY(-$menu-dash-offset);
}

.menu-3 {
    transform: translateY($menu-dash-offset);
}
