﻿.promotion-teaser aside {
    @extend %puff-aside;
}

.promotion-teaser__header {
    @include cell-padding;
    clear: both;
}

.promotion-teaser__preamble {
    @extend %puff-inner-element;
}

.promotion-teaser__image {
    position: relative;
}

.promotion-teaser__theme {
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--color-background-primary);
}

.promotion-teaser__htmlblob {
    @include cell-padding;
}

.promotion-teaser__htmlblob iframe {
    width: 100%;
}
