$dropdown-height: 40px;
$input-width-mobile: 0px;
$input-width-large: 120px;

.date-picker-item {
    position: relative;

    @include mq-tablet-portrait {
        @include flex-center;
        @include inner-divider-horizontal-base;
    }

    > .date-picker-item__icon {
        @include h-item;
        width: $space-large;
        height: $space-large;
        cursor: pointer;
    }

    > input {
        @include reset-input-styles;
        width: $input-width-mobile;
        color: var(--color-foreground-primary);

        @include mq-tablet-portrait {
            width: $input-width-large;
            opacity: 1;
        }
    }

    .picker {
        position: absolute;
        max-width: 315px;
        width: auto;
        top: 100%;
        right: 0;
        z-index: $z-index--date-picker;
    }

    > input {
        cursor: pointer;
    }
}

.date-picker {
    width: 100%;
    display: none;
    padding: 0 3px;

    .opened & {
        display: block;
    }

    .box:before {
        content: " ";
        position: absolute;
        left: 267px;
        border-color: transparent;
        border-bottom-color: var(--color-background-primary);
        border-style: solid;
        border-width: 10px;
        top: -17px;
        right: auto;
    }

    tr {
        > td {
            @include border-right;

            > .date-picker-day {
                cursor: pointer;
                padding: 7px 0;

                &:hover {
                    background: var(--color-background-light);
                }

                &.disabled,
                &.disabled:hover,
                &.disabled.outfocus:hover {
                    background: var(--color-background-light);
                    border-color: #f5f5f5;
                    color: #ddd;
                    cursor: default;
                }

                &.highlighted {
                    background: #159fbc;
                    color: #fff;
                }

                &.outfocus {
                    opacity: 0.66;

                    &:hover {
                        cursor: pointer;
                        background: var(--color-background-light);
                    }
                }

                &.now {
                    font-weight: bold;
                }
            }

            &:first-child {
                @include border-left;
            }
        }

        &:last-child {
            > td {
                .date-picker-day {
                    @include border-bottom;
                }
            }
        }

        .date-picker-weekday {
            @include border-right;
            @include border-bottom;
            font-weight: bold;
            height: 30px;
            padding: 0;
            width: 38px;

            &:last-child {
                @include border-right(transparent);
            }
        }
    }


    .box {
        background: var(--color-background-primary);
        display: table-cell;
        padding: 16px 10px;
        vertical-align: middle;
        box-shadow: 5px 5px 20px rgba(0,0,0,0.6);
    }

    .wrap {
        display: table;
        position: relative;
    }

    .header {
        text-align: center;
        position: relative;
    }

    .table {
        text-align: center;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        font-size: inherit;
        width: 100%;
        margin: 12px 0;

        td {
            margin: 0;
            padding: 0;
        }
    }

    .today {
        border: none;
        background: $color-gray-light;
        font-family: $sr-labs;
        font-weight: bold;
        width: 80px;
        height: $dropdown-height;
        display: inline-block;
        vertical-align: bottom;

        &:focus {
            outline: 2px solid #ff5400;
        }
    }

    .select-month,
    .select-year {
        background: $color-gray-light;
        border: none;
        height: $dropdown-height;
        padding: 8px 5px;

        &:focus {
            outline: 2px solid #ff5400;
        }
    }

    .select-month {
        width: 115px;
        margin-right: 4px;
    }

    .select-year {
        width: 73px;
    }

    .clear {
        display: none;
    }

    .dp-nav-prev,
    .dp-nav-next {
        cursor: pointer;
        top: 0;
        position: absolute;
        width: 37px;
        height: $dropdown-height;
        font-size: 1.6rem;
        background-color: var(--color-background-light);
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .dp-nav-prev {
        left: 0;

        &:before {
            content: '\02190';
        }
    }

    .dp-nav-next {
        right: 0;

        &:before {
            content: '\02192';
        }
    }

    .nav-disabled {
        display: none;
    }

    .nav-disabled,
    .nav-disabled:hover {
        cursor: default;
        background: none;
        border-right-color: #f5f5f5;
        border-left-color: #f5f5f5;
    }
}

.date-picker-item--do-not-show-text {
    opacity: 0;
}

@include mq-tablet-portrait {
    .date-picker {
        .box {
            padding: 16px 10px;
        }
    }
}

@include mq-tablet-portrait {
    .date-picker-item {
        .picker {
            top: 50px;
        }
    }

    .box:before {
        left: 13px;
        right: auto;
    }
}
