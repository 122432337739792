.publication-preamble {
    > ul {
        padding: 0;
    }
}

.publication-preamble {
    a {
        @include link-style;
    }

    p + p {
        @include divider-top-medium;
    }

    &.publication-preamble--plain {
        @include body-font-regular;
        @include body-text-size;
    }
}
