$premium-teaser-gradient-height: 80px;

.premium-teaser {
  @include card-design;
}

.premium-teaser header {
  position: relative;

  &:before {
    background-image: linear-gradient(var(--color-transparent), var(--color-background-primary));
    content: '';
    display: block;
    height: $premium-teaser-gradient-height;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateY(-100%);

    @include mq-tablet-portrait {
      display:none;
    }
  }
}

.premium-teaser__theme {
  @include overline-style;
  @include divider-bottom-base;
}

.premium-teaser__header-inner {
  padding: 0 $space-medium $space-medium;

  @include mq-tablet-portrait {
    padding: $space-medium;
  }

  @include mq-desktop {
    padding: $space-xl;
  }
}
