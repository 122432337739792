﻿.publication-metadata {
    @include caption-style;
    color: var(--color-foreground-secondary);
}

.publication-metadata__item {
    display: inline-block;
    vertical-align: middle;
    margin-right: $space-xs;
}

.rtl {
    .publication-metadata__item {
        margin-right: 0;
        margin-left: $space-xs;
    }
}
