﻿$program-footer-padding: 0;

.program-footer {
    @extend %component-design;
}

.program-footer__info {
    @include grid-gutter;
    padding-bottom: 25px;
    padding-top: 25px;
}

.program-footer__heading {
    @include divider-bottom-medium;
    color: $color-white;
}

.program-footer__image {
    vertical-align: middle;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.program-footer__links {
    @include cell-padding;
}

.teaser-text {
    @include divider-bottom-medium;
}

.teaser-text--inverted {
    color: var(--color-foreground-primary-inverted-lightmode);
}

.program-footer__link-item + .program-footer__link-item {
    @include divider-top-medium;

    @include mq-tablet-portrait {
        margin: 0;
    }
}

.program-footer__broadcast-info {
    @include flex-center;
    color: var(--color-foreground-primary-inverted-lightmode);
}

.program-footer__broadcast-icon {
    @include divider-right-base;
    width: $space-large;
    height: $space-large;
    flex-shrink: 0;
}

@include mq-tablet-portrait {
    .program-footer {
        @include flex(stretch);
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .program-footer__tips {
        width: 100%;
    }

    .program-footer__info {
        float: right;
        order: 1;
        width: 50%;
    }

    .program-footer__image-wrapper {
        float: right;
        order: 2;
        width: 50%;
    }

    .program-footer__links {
        @include flex;
        float: left;
        order: 3;
        width: 100%;
    }

    .program-footer__link-item {
        flex: 1 1 auto;
        float: left;
        width: auto;
        max-width: 450px;
    }
}
