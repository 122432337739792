.keyword-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$space-small;
}

.keyword-list__item {
    margin: 0 $space-small $space-small 0;
}

.rtl {
    .keyword-list__item {
        margin: 0 0 $space-small $space-small;
    }
}
