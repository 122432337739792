﻿.header-section {
    @include h4-style;
    padding: 9px 0 12px;    
}

.header-section--light-background {
    @include border-bottom($color-gray-light);
    color: $color-gray;
}

.header-section--dark-background {
    @include border-bottom($color-gray);
    color: $color-white;
}

.header-section:lang(ar), 
.header-section:lang(ckb), 
.header-section:lang(fa) {
    font-weight:bold;
}

@include mq-tablet-portrait {
    .header-section {
        padding: 14px 0 16px;       
    }    
}
