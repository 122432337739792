﻿$total-columns: 12;

@mixin grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$space-medium;

    > [class^="col-"] {
        padding: $space-medium $space-medium 0 0;
    }

    > [class^="col-"].collapse {
        padding: 0;
    }
}

@mixin grid-row-full-height {
    > * {
        display: flex;
    }
}

@mixin grid-row-collapse {
    margin: 0 (-$space-medium);
}

@mixin grid-component-margin() {
    margin: 0 $space-medium $space-medium;

    @include mq-tablet-portrait {
        margin-left: 0;
        margin-right: 0;
    }
}

.grid-container {
    @include grid-gutter;
}

.grid-container-md {
    @include mq-tablet-portrait {
        @include grid-gutter;
    }
}

.row {
    @include grid-row;
}

.row--full-height {
    @include grid-row-full-height;
}

.row--collapse {
    @include grid-row-collapse;
}

@for $i from 1 through $total-columns {
    .col-xs-#{$i} {
        width: percentage(math.div($i, $total-columns));
    }
}

@for $i from 1 through $total-columns {
    .col-sm-#{$i} {
        @include mq-mobile-large {
            width: percentage(math.div($i, $total-columns));
        }
    }
}

@for $i from 1 through $total-columns {
    .col-md-#{$i} {
        @include mq-tablet-portrait {
            width: percentage(math.div($i, $total-columns));
        }
    }
}

@for $i from 1 through $total-columns {
    .col-lg-#{$i} {
        @include mq-tablet-portrait {
            width: percentage(math.div($i, $total-columns));
        }
    }
}

.gutter {
    @include grid-gutter;
}

