﻿.rss-item__anchor {
    @include h6-style;
    text-decoration: none;
    display: block;
}

.rss-item__date {
    @include caption-style;
}

