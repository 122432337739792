﻿.related-list__heading {
    @include grid-gutter;
    padding-top: $space-medium;
}

.related-list__item + .related-list__item {
    @include border-top;
}

@include mq-tablet-portrait {

    .related-list__item {
        width: auto;
    }

    .related-list__item + .related-list__item {
        @include border-top;
        border-left: 0;
    }
}
