﻿.teaser-related-list__item {
    @include cell-padding;
    flex-shrink: 0;
}

.teaser-related-list__item + .teaser-related-list__item {
    @include border-top;
}

@include mq-tablet-portrait {
    .teaser-related-list {
        display: block;
        overflow: hidden;
    }

    .teaser-related-list__item {
        padding: $space-medium;
        width: auto;
    }

    .teaser-related-list__item + .teaser-related-list__item {
        border-left: 0;
        @include border-top;
    }
}
