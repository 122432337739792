﻿.feature-list-item {
    @include border-top($color-gray-light);
    padding: 10px 0;
}

.feature-list-item__starttime {
    padding: 0 10px 10px 0;
    float: left;
}

.feature-list-item__details {
    overflow: hidden;
}
