﻿.vma {
    background-color: $color-red;
}

.vma__header {
    color: $color-white;
    cursor: pointer;
}

.vma__button {
    @include reset-input-styles;
    @include flex-center;
    justify-content: space-between;
    width: 100%;
    padding: $space-medium 0;
    color: $color-white;
    text-align: left;

    @include mq-tablet-portrait {
        justify-content: flex-start;
    }
}

.vma__header-icon {
    display: inline-block; // Fallback för browsers som inte klarar flex
    @include flex-center;
    @include divider-left-xs;
    justify-content: center;
    width: $space-medium;
    height: $space-medium;
    transition: transform .15s ease-out;

    @include mq-tablet-portrait {
        @include divider-left-small;
    }

    svg {
        width: 100%;
    }
}

.vma__header-icon--active {
    transform: rotate(180deg);
}
