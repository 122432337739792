﻿.puff-audio-large__header {
    @include cell-padding;
    clear: both;
}

.puff-audio-large aside {
    @extend %puff-aside;
}

.puff-audio-large__header .heading {
    @include mq-desktop-large {
        @include h1-size;
    }
}

.puff-audio-large__preamble,
.puff-audio-large__poll {
    @extend %puff-inner-element;
}

.puff-audio-large__image-wrapper,
.puff-audio-large__figure {
    position: relative;
}

.puff-audio-large__video-tag {
    @include caption-style($color: $color-white);
    @include flex-center;
    position: absolute;
    top: $space-small;
    right: $space-small;
    background: $color-black;
    padding: $space-xs $space-small;
    border-radius: $border-radius;
    line-height: 1;
    text-transform: uppercase;

    @include mq-tablet-portrait {
        top: $space-medium;
        right: $space-medium;
        padding: $space-small;
    }
}

.puff-audio-large__video-tag svg {
    @include divider-right-base;
    width: $space-medium;
    height: $space-medium;
}

.puff-audio-large:not(.puff-audio-large--feature) figure + .puff-audio-large__theme {
    position: absolute;
    bottom: 0;
    background-color: var(--color-background-primary);
    max-width: 75%;
}

.puff-audio-large--feature {
    overflow: hidden;

    > * {
        @include border-thick-left;
    }

    .puff-audio-large__image-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin-left: -$space-xs;
    }
}

@include mq-tablet-portrait {
    .puff-audio-large {
        padding-bottom: 0;
    }
}
