@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';












































$handle-dimension: $space-small * 6;

@keyframes pulsate-1 {
    from,
    to {
        transform: scale(1);
        opacity: 0;
    }

    50% {
        transform: scale(2);
        opacity: 0.25;
    }

    90% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulsate-2 {
    from,
    to {
        transform: scale(1);
        opacity: 0;
    }

    50% {
        transform: scale(3);
        opacity: 0.15;
    }

    90% {
        transform: scale(3);
        opacity: 0;
    }
}

.dot {
    position: relative;
    width: $space-medium;
    height: $space-medium;
    border-radius: 50%;
    border: 2px solid $color-red;
    background-color: $color-white;
}

.ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $space-medium;
    height: $space-medium;

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100%;
        opacity: 0;
        background-color: $color-red;
        transform-origin: center center;
    }
}

.ripple:before {
    animation: pulsate-1 2s linear infinite;
}

.ripple:after {
    animation: pulsate-2 2s 0.25s linear infinite;
}

.live-marker {
    @include flex-v-h-center;
    @include clickable-design;
    position: absolute;
    top: 50%;
    width: $handle-dimension;
    height: $handle-dimension;
    margin-top: -(math.div($handle-dimension, 2));
    margin-left: -(math.div($handle-dimension, 2));
}
