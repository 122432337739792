﻿.icon-link {
    text-decoration: none;
}

.icon-link__icon {
    float: left;
    width: 7px;
    height: 7px;
    margin: -1px 5px 0 0;
    vertical-align: top;
}

.icon-link--big-icon {
    width: 10px;
    height: 10px;
    margin-top: 0;
}

.rtl {
    .icon-link__icon {
        float: right;
        margin: -1px 0 0 5px;
    }
}
