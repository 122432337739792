@use "sass:math";

// Variables
@import '../../../../style/variables/spacing';
@import '../../../../style/variables/dimensions';
@import '../../../../style/variables/border-variables';
@import '../../../../style/variables/colors';
@import '../../../../style/variables/z-indexes';
@import '../../../../style/variables/easings';

// Typography
@import '../../../../style/typography/fonts';

// Mixins
@import '../../../../style/mixins/display';
@import '../../../../style/mixins/background';
@import '../../../../style/mixins/media-queries';
@import '../../../../style/mixins/grid-gutters';
@import '../../../../style/mixins/padding';
@import '../../../../style/mixins/dividers';
@import '../../../../style/mixins/borders';
@import '../../../../style/mixins/border-header';
@import '../../../../style/mixins/flex';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/icon';
@import '../../../../style/mixins/puff-common';
@import '../../../../style/mixins/image';
@import '../../../../style/mixins/box-shadow';
@import '../../../../style/mixins/card';
@import '../../../../style/mixins/card-list';
@import '../../../../style/mixins/component-design';
@import '../../../../style/mixins/container';
@import '../../../../style/mixins/clickable-design';
@import '../../../../style/mixins/button';
@import '../../../../style/mixins/link';
@import '../../../../style/mixins/thumbnail';
@import '../../../../style/mixins/audio-heading';
@import '../../../../style/mixins/full-page-style';
@import '../../../../style/mixins/iframe';
@import '../../../../style/mixins/forms';
@import '../../../../style/mixins/ellipsis';
@import '../../../../style/mixins/focus';
@import '../../../../style/mixins/gradient';

// Ui
@import '../../../../style/ui/ui-section-header';
@import '../../../../style/ui/ui-show-more-button';







































.root {
    display: flex;
}

a {
    @include caption-style;
    @include flex-center;
    @include cell-padding-horizontal-base;
    flex: 0 0 auto;
}

.active {
    @include body-font-bold;
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

a:first-child {
    padding-left: $space-medium;
}

@include mq-mobile-medium {
    a {
        @include cell-padding-vertical-medium;
        font-size: $fs-medium;
    }
}

@include mq-tablet-portrait {
    a {
        @include flex-center;
        padding-left: $space-medium;
    }
}

.strong {
    @include body-font-bold;
}
