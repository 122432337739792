﻿%puff-inner-element {
    @include grid-gutter;
    padding-bottom: $space-medium;
}

%puff-aside {
    @include border-top;
}

%puff-padding-top {
    padding-top: $space-medium;
}

//Puff has-image modifier mixin
@mixin puff-with-image-style() {
    &#{&}--has-image {
        padding-top: 0;
    }
}
