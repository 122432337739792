.info-teasers-container {
    @include component-divider;
}

.info-teasers-container__item {
    @include card-design;
    display: flex;
}

.info-teasers-container__item + .info-teasers-container__item {
    @include divider-top-medium;
}

.info-teaser-container__title {
    @include cell-padding;
    background-color: var(--color-highlight);
    flex-shrink: 0;

    @include mq-tablet-portrait {
        @include flex;
    }

    @include mq-desktop-large {
        @include flex-center;
    }
}

.info-teaser-container__title .heading {
    line-height: inherit;
    text-transform: uppercase;
    color: var(--color-foreground-primary-lightmode);
}

.info-teaser-container__border {
    @include border-thick-left;
    flex: 1;
}
