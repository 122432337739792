﻿.music-toplist {
    @extend %component-design;
    @include page-padding-top;
}

.music-toplist__header {
    @include grid-gutter;
    @include flex-items-on-sides;
    @include border-bottom;
    padding-bottom: $space-medium;
}

.music-toplist__title {
    @include h2-style;
}
