﻿.puff-nedryckare {
    @include body-font-semi-bold;

    &--nowrap {
        white-space: nowrap;
    }
}

.puff-nedryckare--inverted {
    color: $color-white;
}
