$color-white: #fff;
$color-white-transparent: rgba(255, 255, 255, 0);

$color-black: #111;
$color-black-transparent: rgba(0, 0, 0, .5);


$color-blue: #1642A8;
$color-blue-light: #2068a6;
$color-blue-lightest: #d0d9ee;

$color-gray-dark: #262626;
$color-gray: #757575;
$color-gray-light: #ebebeb;
$color-gray-lightest: #f3f3f3;

$color-white-light: #ffffff;

$color-red: #e62143;
$color-red-dark: #9f172e;

$color-yellow: #fef901;
$color-light-yellow: #FFFEEB;

$color-box-shadow-subtle: rgba(0, 0, 0, 0.03);
$color-box-shadow-contrast: rgba(0, 0, 0, .1);

$bg-opacity: .95;
$darken-percent: 11%;

$tab-focus-color: #ff5400;

$no-theme-default: #000;
$p1-default: #0167c6;
$p2-default: #ff5c00;
$p3-default: #08b46a;
$p4-default: #cc24b4;
$p6-default: #0c2d6f;
$red-default: #b51d1a;
$green-default: #6D9F21;
$sportgreen-default: #0f8f0f;
$same-default: #0a2d6f;
$sisu-default: #0a2d6f;
$junior-web-default: #9533ff;
$ekot-default: #0a2d6f;

$no-theme-dark: #000;
$p1-dark: #0167c6;
$p2-dark: #ff5c00;
$p3-dark: #08b46a;
$p4-dark: #cc24b4;
$p6-dark: $p6-default;
$red-dark: darken($red-default, $darken-percent);
$green-dark: darken($green-default, $darken-percent);
$sportgreen-dark: #0e8618;
$same-dark: $same-default;
$sisu-dark: #0a2d6f;
$junior-web-dark: #9533ff;
$ekot-dark: #0a2d6f;

$no-theme-light: $color-gray-lightest;
$p1-lighten-percent: 60%;
$p1-light: lighten($p1-dark, $p1-lighten-percent);
$p2-lighten-percent: 52%;
$p2-light: lighten($p2-dark, $p2-lighten-percent);
$p3-lighten-percent: 67%;
$p3-light: lighten($p3-dark, $p3-lighten-percent);
$p4-lighten-percent: 57%;
$p4-light: lighten($p4-dark, $p4-lighten-percent);
$junior-web-lighten-percent: 45%;
$junior-web-light: lighten($junior-web-default, $junior-web-lighten-percent);
$ekot-lighten-percent: 57%;
$ekot-light: lighten($ekot-default, $ekot-lighten-percent);
$p6-light: $no-theme-light;
$red-light: $no-theme-light;
$green-light: $no-theme-light;
$sportgreen-light: $no-theme-light;
$same-light: $no-theme-light;
$sisu-light: $no-theme-light;

$menu-gradient-transparent: rgba(243, 243, 243, 0);
