.channel-carousel-skeleton__scroll-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 (-1rem);
    padding-left: 1rem;

    @include mq-desktop {
        overflow: visible;
    }
}

.channel-carousel-skeleton__image {
    background-color: $color-gray-lightest;
}

.channel-carousel-skeleton__schedule {
    height: 54px;
}

.channel-carousel-skeleton__mobile {
  @include mq-tablet-portrait {
    display: none;
  }
}

.channel-carousel-skeleton__tablet {
  display: none;
  @include mq-tablet-portrait {
    display: block;
  }
}
