$image-width: 5rem;
$image-height: 5rem;
$image-width-desktop: 10rem;
$image-height-desktop: 10rem;
$gradient-stop: $image-width + $space-medium;
$gradient-stop-desktop: ($image-height-desktop) + $space-medium;
$background-blur: $space-xs;
$background-blur-desktop: $space-medium;

.program-menu {
    @include border-bottom;
    position: relative;
    overflow: hidden;
    padding-top: $image-height;
    background-color: $color-white;

    @include mq-tablet-portrait {
        padding-top: $image-height-desktop;
    }
}

.title-link {
    @include border-right($color-gray-light);
    @include flex-center;
    white-space: nowrap;

    @include mq-tablet-portrait {
        align-items: flex-start;
        padding-left: 0;
    }
}

.links-wrapper {
    @include mq-tablet-portrait {
        flex-wrap: wrap;
    }
}

.image {
    width: 100%;
    display: block;
    height: auto;
}

.program-menu__image-wrapper {
    @include border-radius;
    width: $image-width;
    height: $image-height;
    overflow: hidden;

    @include mq-tablet-portrait {
        width: $image-width-desktop;
        height: $image-height-desktop;
    }
}

.program-menu__title {
    flex: 1;
}

.program-menu__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    filter: blur($background-blur);
    transform: scale(1.05);
    pointer-events: none;

    @include mq-tablet-portrait {
      filter: blur($background-blur-desktop);
      transform: scale(1.25);
    }
}

.program-menu__background:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient( var(--color-transparent), var(--color-background-primary) $gradient-stop );
    pointer-events: none;

    @include mq-tablet-portrait {
        background-image: linear-gradient( var(--color-transparent), var(--color-background-primary) $gradient-stop-desktop );
    }
}

.program-menu__content {
    position: relative;
}

.links {
    margin: 0 (-$space-medium);
}

.program-menu__category-info {
    @include caption-style;
    @include divider-top-base;
    padding: $space-xs $space-medium;
    border-radius: $space-xl;
    border: 1px solid $color-gray-light;

    @include mq-tablet-portrait {
        @include body-text-size;
        @include divider-top-small;
        padding: $space-small $space-medium;
    }
}

.collaboration-icon {
    width: auto;
    height: auto;
}

.program-menu__category-icon {
    width: $space-medium;
    height: $space-medium;
}
