﻿.toggler {
    .toggle-click {
        @include body-font-bold;
        cursor: pointer;
    }

    .toggle-hidden {
        display: none;
    }

    .is-expanded {
        display: block;
    }

    .is-collapsed-label,
    .is-expanded-label,
    .is-loading-label {
        display: none;
    }

    &.is-collapsed .is-collapsed-label,
    &.is-expanded .is-expanded-label,
    &.is-loading .is-loading-label {
        display: block;
    }

    a {
        text-decoration: none;
    }
}
