﻿$tag-border-radius: $space-large;

.tag-button {
    @include border;
    display: inline-block;
    padding: $space-small $space-medium;
    border-radius: $tag-border-radius;
    color: var(--color-foreground-primary);
    text-decoration: none;
    outline: none;
}

.tag-button:hover {
    text-decoration: underline;
}
