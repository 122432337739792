@use "sass:math";

// Variables
@import '../../../../../style/variables/spacing';
@import '../../../../../style/variables/dimensions';
@import '../../../../../style/variables/border-variables';
@import '../../../../../style/variables/colors';
@import '../../../../../style/variables/z-indexes';
@import '../../../../../style/variables/easings';

// Typography
@import '../../../../../style/typography/fonts';

// Mixins
@import '../../../../../style/mixins/display';
@import '../../../../../style/mixins/background';
@import '../../../../../style/mixins/media-queries';
@import '../../../../../style/mixins/grid-gutters';
@import '../../../../../style/mixins/padding';
@import '../../../../../style/mixins/dividers';
@import '../../../../../style/mixins/borders';
@import '../../../../../style/mixins/border-header';
@import '../../../../../style/mixins/flex';
@import '../../../../../style/mixins/forms';
@import '../../../../../style/mixins/icon';
@import '../../../../../style/mixins/puff-common';
@import '../../../../../style/mixins/image';
@import '../../../../../style/mixins/box-shadow';
@import '../../../../../style/mixins/card';
@import '../../../../../style/mixins/card-list';
@import '../../../../../style/mixins/component-design';
@import '../../../../../style/mixins/container';
@import '../../../../../style/mixins/clickable-design';
@import '../../../../../style/mixins/button';
@import '../../../../../style/mixins/link';
@import '../../../../../style/mixins/thumbnail';
@import '../../../../../style/mixins/audio-heading';
@import '../../../../../style/mixins/full-page-style';
@import '../../../../../style/mixins/iframe';
@import '../../../../../style/mixins/forms';
@import '../../../../../style/mixins/ellipsis';
@import '../../../../../style/mixins/focus';
@import '../../../../../style/mixins/gradient';

// Ui
@import '../../../../../style/ui/ui-section-header';
@import '../../../../../style/ui/ui-show-more-button';


































button {
    @include reset-input-styles;
    padding: 0;
}
.characters-navigation {
    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);

        @include mq-mobile-large {
            grid-template-columns: repeat(12, 1fr);
        }

        @include mq-tablet-portrait {
            grid-template-columns: repeat(16, 1fr);
        }

        @include mq-desktop-large {
            grid-template-columns: repeat(20, 1fr);
        }
    }
}
