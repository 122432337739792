$fonts: ('h1', 'h2', 'h3', 'h4', 'h5', 'body', 'caption', 'overline1', 'overline2');
$sizes: (0, 1, 2, 3, 4, 5, 6, 7 , 8);

@each $font in $fonts {
  .font-#{$font} {
    font: var(--font-#{$font});
  }
}

@each $s in $sizes {
  .gap-#{$s} {
    gap: var(--size-#{$s});
  }

  .w-#{$s} {
    width: var(--size-#{$s});
  }

  .h-#{$s} {
    height: var(--size-#{$s});
  }
}
