﻿@mixin h-item {
    display: inline-block;
    vertical-align: middle;
}

@mixin v-center-parent{
    &:before {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        content: '';
    }
}

@mixin v-center-child {
    display: inline-block;
    vertical-align: middle;
}
