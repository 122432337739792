﻿a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .list-bullet {
        list-style-type: disc;

        li {
            margin-left: 16px;
            padding: 2px;
        }
    }
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

:focus {
    outline: none;
}
 