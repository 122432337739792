$button-height: $space-small * 7;

@mixin button {
    @include reset-input-styles;
    @include button-text-style;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $space-medium $space-xl;
    border-radius: $space-xl;
    height: $button-height;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    background: var(--color-background-primary-inverted);
    color: var(--color-foreground-primary-inverted);
}

@mixin button-transparent() {
    @include reset-input-styles;
    @include flex-center;
    @include grid-gutter;
}

@mixin player-control-button {
    @include reset-input-styles;
    @include flex-center;
    justify-content: center;
    width: 44px;
    padding: 0;
    flex-shrink: 0;
    transition: color 0.25s ease;
    color: var(--color-foreground-primary);

    &.large {

        svg {
            width: $space-small * 6;
            height: $space-small * 6;
        }
    }

    &:active:not(:disabled) svg {
        transform: scale(0.8);
    }

  &:disabled {
    color: $color-gray-light;
    @include dark-mode {
      color: var(--color-foreground-tertiary);
    }
  }

    svg {
        transition: transform 0.1s ease;
        width: $space-xl;
        height: $space-xl;
    }
}

$carousel-button-width: 216px;

@mixin player-carousel-button {
    @include reset-input-styles;
    display: none;
    position: absolute;
    top: 0;
    width: $carousel-button-width;
    max-width: none;
    height: 100%;
    z-index: $z-index--player-gradient;
    padding: 0 $space-xl;
    color: var(--color-foreground-primary-lightmode);

    @include mq-desktop-large {
        @include flex-v-h-center;
    }

    .icon {
        @include box-shadow-small($color-box-shadow-contrast);
        @include flex-center;
        justify-content: center;
        width: $space-small * 7;
        height: $space-small * 7;
        border-radius: $space-xl;
        background-color: var(--color-background-primary-lightmode);
    }
}
