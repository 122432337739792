﻿.caption {
    @include caption-style;

    &.strong {
        @include caption-style-strong;
    }
}

.caption--link{
    @include cell-padding-vertical;
    @include block-link;
}
