﻿@mixin flex($align: flex-start) {
    display: flex;
    align-items: $align;
}

@mixin flex-wrap() {
    flex-wrap: wrap;
}

@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin flex-v-h-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-items-on-sides($align: flex-start) {
    display: flex;
    align-items: $align;
    justify-content: space-between;
}

@mixin flex-child-full-height {
    display: flex;
    flex-direction: column;
    flex: 1;
}
