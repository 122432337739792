﻿.external-link-with-icon {
    @include link-style;
    @include cell-padding-vertical;
    display: block;
}

.external-link-with-icon > * {
    @include h-item;
}

.external-link-with-icon__icon {
    @include divider-left-base;
    width: $space-medium;
    height: $space-medium;
}
