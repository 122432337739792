.internal-link {
    @include block-link;
    @include default-link;
    @include cell-padding;

    &:after {
        content: "\00a0\279D";
    }

    .text-editor-content & {
        display: inline;
        padding: 0;
        &:after {
            display: none;
        }
    }
}
