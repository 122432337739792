﻿.menu-scroll-wrapper {
    position: relative;
    display: flex;
    flex: 1;
}

.menu-scroll-wrapper:after {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: $space-medium;
    display: block;
    content: '';
    pointer-events: none;
    background-image: linear-gradient(90deg, $color-white-transparent, $color-white);

    @include mq-tablet-portrait {
        display: none;
    }

    &.menu-scroll-wrapper--disable-scroll-desktop & {
        display: block;

        @include mq-desktop-large {
            display: none;
        }
    }
}

.menu-scroll-gray:after {
    background-image: linear-gradient(90deg, $menu-gradient-transparent, $color-gray-light);
}

.menu-scroll-wrapper--long-shadow:after {
    width: $space-xxxl;
}

.menu-scroll-content {
    display: flex;
    flex: 1;
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @include mq-mobile-medium {
        overflow: hidden;
    }

    .menu-scroll-wrapper--disable-scroll-desktop & {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @include mq-desktop-large {
            overflow: hidden;
        }
    }
}

.menu-scroll-inner {
    padding-left: $space-medium;
    @include mq-tablet-portrait {
        padding: 0;
    }
}