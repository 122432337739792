﻿.unit-episode-group__wrapper {
    @extend %component-design;
    @include cell-padding;
}

.unit-episode-group__header {
    @include divider-bottom-small;
}

.unit-episode-group__divider {
    display: none;
}

.unit-episode-group {
    margin-bottom: $space-medium;
}

.unit-episode-group:last-child {
    margin-bottom: 0;
}
