$button-height: $space-small * 7;

@mixin ui-show-more-button {
    @include caption-style;
    @include caption-style-strong;
    @include card-design;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $button-height;
    width: 100%;
    padding: $space-small;
    appearance: none;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background: var(--color-background-light);
    color: var(--color-foreground-primary);

    svg {
        width: $space-medium;
        height: $space-medium;
        fill: $color-black;
        margin: 0 $space-small 0 0;
    }
}
