.disclaimer-box {
    @include card-design;
    @include cell-padding;

    @include mq-tablet-portrait {
        border-radius: 0;
        border: 0;
    }
}

.disclaimer-box__inner {
    @include container-narrow;
}

.disclaimer-box__text {
    margin-left: -$space-xs;
    @include full-page-style {
        @include mq-tablet-portrait {
            margin: 0;
        }
    }
}

.disclaimer-box__link {
    @include cell-padding;
    display: block;
    text-decoration: underline;
    margin: 0 (-$space-medium);
}
