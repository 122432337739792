@mixin label {
    @include caption-style;
    display: block;

    &.label-white {
        color: $color-white;
    }

    &.label-heading {
        padding-bottom: 7px;
        clear: both;
    }
}

.label {
    @include label;
}

.center {
    margin: 0 auto;
}

.left {
    float: left;
}

.right {
    float: right;
}

.label-strong {
    text-transform: uppercase;
}

.phone {
    white-space: nowrap;
}

.section-heading {
    @include h4-style;
    padding: 9px 0 12px;

    &.section-heading-white {
        @include border-bottom($color-gray-light);
        color: $color-gray;
    }

    &.section-heading-black {
        @include border-bottom($color-gray);
        color: $color-white;
    }

    &.section-heading-black-no-border {
        color: $color-white;
    }

    &.section-heading-black-no-border {
        color: $color-white;
    }
}

.section-heading:lang(ar),
.section-heading:lang(ckb),
.section-heading:lang(fa) {
    font-weight: bold;
}

.capitalized:first-child {
    text-transform: capitalize;
}

@include mq-tablet-portrait {
    .section-heading {
        padding: 14px 0 16px;
    }
}
