.system-message, .system-message a {
  background-color: var(--color-highlight-static);
  color: var(--color-foreground-primary-inverted-static);
}

.system-message__button {
  --offset:calc(var(--size-2) * -1);
  padding: var(--size-2);
  margin-right: var(--offset);
  margin-left: auto;
}
