@use "sass:math";

// Variables
@import '../../../style/variables/spacing';
@import '../../../style/variables/dimensions';
@import '../../../style/variables/border-variables';
@import '../../../style/variables/colors';
@import '../../../style/variables/z-indexes';
@import '../../../style/variables/easings';

// Typography
@import '../../../style/typography/fonts';

// Mixins
@import '../../../style/mixins/display';
@import '../../../style/mixins/background';
@import '../../../style/mixins/media-queries';
@import '../../../style/mixins/grid-gutters';
@import '../../../style/mixins/padding';
@import '../../../style/mixins/dividers';
@import '../../../style/mixins/borders';
@import '../../../style/mixins/border-header';
@import '../../../style/mixins/flex';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/icon';
@import '../../../style/mixins/puff-common';
@import '../../../style/mixins/image';
@import '../../../style/mixins/box-shadow';
@import '../../../style/mixins/card';
@import '../../../style/mixins/card-list';
@import '../../../style/mixins/component-design';
@import '../../../style/mixins/container';
@import '../../../style/mixins/clickable-design';
@import '../../../style/mixins/button';
@import '../../../style/mixins/link';
@import '../../../style/mixins/thumbnail';
@import '../../../style/mixins/audio-heading';
@import '../../../style/mixins/full-page-style';
@import '../../../style/mixins/iframe';
@import '../../../style/mixins/forms';
@import '../../../style/mixins/ellipsis';
@import '../../../style/mixins/focus';
@import '../../../style/mixins/gradient';

// Ui
@import '../../../style/ui/ui-section-header';
@import '../../../style/ui/ui-show-more-button';




















.menu-scroll-wrapper {
    --transparent-color: rgba(0, 0, 0, 0);
    position: relative;
    display: flex;
    flex: 1;
}

.menu-scroll-wrapper:after {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: $space-medium;
    display: block;
    content: '';
    pointer-events: none;
    background-image: linear-gradient(90deg, var(--transparent-color), var(--color-background-primary));

    @include mq-tablet-portrait {
        display: none;
    }

    &.menu-scroll-wrapper--disable-scroll-desktop & {
        display: block;

        @include mq-desktop-large {
            display: none;
        }
    }
}

.gray:after {
    background-image: linear-gradient(90deg, var(--transparent-color), var(--color-background-light));
}

.menu-scroll-wrapper--long-shadow:after {
    width: $space-xxxl;
}

.menu-scroll-content {
    display: flex;
    flex: 1;
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .menu-scroll-wrapper--disable-scroll-desktop & {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.menu-scroll-inner {
    padding-left: $space-medium;
    @include mq-tablet-portrait {
        padding: 0;
    }
}
