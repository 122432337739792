﻿.related-link-list__list {
    @include border-radius;
    @include border;
    overflow: hidden;
}

.related-link-list__header {
    @include divider-bottom-medium;
}

.related-link-list__list-item {
    @include border-thick-left(var(--color-background-primary-inverted));
    overflow: hidden;
    margin-left: -1px;
    padding-left: $space-medium;
}

.related-link-list__list-item + .related-link-list__list-item {
    @include border-top;
}
