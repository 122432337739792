@mixin block-link {
    display: block;
}

@mixin default-link {
    color: var(--color-foreground-primary);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
        text-decoration: underline;
        text-decoration-color: currentColor;
    }
}
