﻿.gallery__caption {
    @include caption-style;
    background-color: var(--color-background-light);

    @include mq-desktop {
        display: flex;
        justify-content: space-between;
    }
}

.gallery__caption-inner {
    @include cell-padding;
}
